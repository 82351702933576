/*
 * @package    CNFPT
 * @project      AED
 * @author    <www.cnfpt.fr>
* @copyright  Copyright: (c) 2017 CNFPT
* @license    All right reserved.
*/

/*------------------------------------*\
    JOBS
\*------------------------------------*/

.month-selector {
  .form-control {
    width: auto;
    display: inline-block;
  }
}

.dropdown-header {
  font-weight: 600;
}