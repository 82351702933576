/*
 * @package    CNFPT
 * @project      AED
 * @author    <www.cnfpt.fr>
* @copyright  Copyright: (c) 2017 CNFPT
* @license    All right reserved.
*/

/*------------------------------------*\
    ICONS
\*------------------------------------*/
@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?mz50jk');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?mz50jk#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?mz50jk') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?mz50jk') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?mz50jk##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="aed-icon-"], [class*=" aed-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.aed-icon-calendar {
  &:before {
    content: $aed-icon-calendar;
  }
}
.aed-icon-eye-crossed {
  &:before {
    content: $aed-icon-eye-crossed;
  }
}
.aed-icon-pdf {
  &:before {
    content: $aed-icon-pdf;
  }
}
.aed-icon-html {
  &:before {
    content: $aed-icon-html;
  }
}
.aed-icon-tumblr {
  &:before {
    content: $aed-icon-tumblr;
  }
}
.aed-icon-viadeo {
  &:before {
    content: $aed-icon-viadeo;
  }
}
.aed-icon-back {
  &:before {
    content: $aed-icon-back;
  }
}
.aed-icon-stop {
  &:before {
    content: $aed-icon-stop;
  }
}
.aed-icon-feather {
  &:before {
    content: $aed-icon-feather;
  }
}
.aed-icon-cv {
  &:before {
    content: $aed-icon-cv;
  }
}
.aed-icon-edit {
  &:before {
    content: $aed-icon-edit;
  }
}
.aed-icon-smartphone {
  &:before {
    content: $aed-icon-smartphone;
  }
}
.aed-icon-rewind {
  &:before {
    content: $aed-icon-rewind;
  }
}
.aed-icon-fast-forward {
  &:before {
    content: $aed-icon-fast-forward;
  }
}
.aed-icon-power {
  &:before {
    content: $aed-icon-power;
  }
}
.aed-icon-info {
  &:before {
    content: $aed-icon-info;
  }
}
.aed-icon-play {
  &:before {
    content: $aed-icon-play;
  }
}
.aed-icon-play-full {
  &:before {
    content: $aed-icon-play-full;
  }
}
.aed-icon-square {
  &:before {
    content: $aed-icon-square;
  }
}
.aed-icon-square-full {
  &:before {
    content: $aed-icon-square-full;
  }
}
.aed-icon-add {
  &:before {
    content: $aed-icon-add;
  }
}
.aed-icon-at {
  &:before {
    content: $aed-icon-at;
  }
}
.aed-icon-bookmark {
  &:before {
    content: $aed-icon-bookmark;
  }
}
.aed-icon-briefcase {
  &:before {
    content: $aed-icon-briefcase;
  }
}
.aed-icon-check {
  &:before {
    content: $aed-icon-check;
  }
}
.aed-icon-circle {
  &:before {
    content: $aed-icon-circle;
  }
}
.aed-icon-clock {
  &:before {
    content: $aed-icon-clock;
  }
}
.aed-icon-close {
  &:before {
    content: $aed-icon-close;
  }
}
.aed-icon-contrast {
  &:before {
    content: $aed-icon-contrast;
  }
}
.aed-icon-down-arrow {
  &:before {
    content: $aed-icon-down-arrow;
  }
}
.aed-icon-download {
  &:before {
    content: $aed-icon-download;
  }
}
.aed-icon-email {
  &:before {
    content: $aed-icon-email;
  }
}
.aed-icon-eye {
  &:before {
    content: $aed-icon-eye;
  }
}
.aed-icon-facebook {
  &:before {
    content: $aed-icon-facebook;
  }
}
.aed-icon-family-tree {
  &:before {
    content: $aed-icon-family-tree;
  }
}
.aed-icon-folder {
  &:before {
    content: $aed-icon-folder;
  }
}
.aed-icon-hash {
  &:before {
    content: $aed-icon-hash;
  }
}
.aed-icon-heart {
  &:before {
    content: $aed-icon-heart;
  }
}
.aed-icon-home {
  &:before {
    content: $aed-icon-home;
  }
}
.aed-icon-left-arrow {
  &:before {
    content: $aed-icon-left-arrow;
  }
}
.aed-icon-linkedin {
  &:before {
    content: $aed-icon-linkedin;
  }
}
.aed-icon-locked {
  &:before {
    content: $aed-icon-locked;
  }
}
.aed-icon-minus {
  &:before {
    content: $aed-icon-minus;
  }
}
.aed-icon-phone {
  &:before {
    content: $aed-icon-phone;
  }
}
.aed-icon-placeholder {
  &:before {
    content: $aed-icon-placeholder;
  }
}
.aed-icon-printer {
  &:before {
    content: $aed-icon-printer;
  }
}
.aed-icon-right-arrow {
  &:before {
    content: $aed-icon-right-arrow;
  }
}
.aed-icon-rotate {
  &:before {
    content: $aed-icon-rotate;
  }
}
.aed-icon-rss {
  &:before {
    content: $aed-icon-rss;
  }
}
.aed-icon-search {
  &:before {
    content: $aed-icon-search;
  }
}
.aed-icon-settings {
  &:before {
    content: $aed-icon-settings;
  }
}
.aed-icon-star {
  &:before {
    content: $aed-icon-star;
  }
}
.aed-icon-tag {
  &:before {
    content: $aed-icon-tag;
  }
}
.aed-icon-trash {
  &:before {
    content: $aed-icon-trash;
  }
}
.aed-icon-twitter {
  &:before {
    content: $aed-icon-twitter;
  }
}
.aed-icon-up-arrow {
  &:before {
    content: $aed-icon-up-arrow;
  }
}
.aed-icon-user {
  &:before {
    content: $aed-icon-user;
  }
}
.aed-icon-worldwide {
  &:before {
    content: $aed-icon-worldwide;
  }
}
