/*
 * @package    CNFPT
 * @project      AED
 * @author    <www.cnfpt.fr>
* @copyright  Copyright: (c) 2017 CNFPT
* @license    All right reserved.
*/

/*------------------------------------*\
    SIDEBAR
\*------------------------------------*/

#sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: -40rem;
    width: 40rem;
    height: 100%;
    color: #ffffff;
    background: $third-color;
    border-right: 1.4rem solid $primary-color;
    z-index: 1000;
    overflow-y: auto;
    @include transition-all(0.6s);

    &.slided {
        left: 0;
    }

    perfect-scrollbar {
        padding: 3rem 1.5rem;
    }

    nav {
        margin-bottom: 3rem;

        ul {
            margin-bottom: 0;

            li {
                margin-bottom: 0.1rem;

                a {
                    display: block;
                    padding: 0 1rem;
                    line-height: 3.2rem;
                    color: #ffffff;
                    @include transition-all(0.6s);
                }
            }
        }

        .admin-links {
          margin-bottom: 2rem;

          .header {
            display: block;
            background: #ffffff;
            padding: 0 1rem;
            line-height: 3.2rem;
            background-color: lighten($primary-color, 5%);
            margin-bottom: 0.1rem;
          }
          li {
            a {
                background: lighten($fourth-color, 5%);

                &:hover, &:focus {
                  background: lighten($fourth-color, 10%);
                }
            }
          }

        }

        .base-links {
          li {
            a {
              background: lighten($third-color, 5%);

              &:hover, &:focus {
                  background: lighten($third-color, 10%);
              }
            }
          }
        }
    }

    .aed-map-container {
        padding-top: 2rem;
        border-top: 0.1rem solid lighten($third-color, 5%);

        .ahm-map {
            .form-check {
                position: relative;
                z-index: 1;
            }

            app-loader-small {
                .spinner-small {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin: -2rem -2rem;
                }
            }

            #aed-map {
                position: relative;
                left: -0.5rem;
                top: -4rem;
                overflow: visible;
            }
        }

        .ahm-infos {

            h3 {
                min-height: 4.8rem;
                margin-bottom: 2rem;
            }

            .ahm-selected {
                margin-bottom: 0;
                text-align: center;

                .as-number, .as-icon, .as-label {
                    display: inline-block;
                }

                .as-number {
                    position: relative;
                    top: -1.2rem;
                    margin-right: 1rem;
                    font-size: 4rem;
                    @include ultra-bolded;
                    line-height: 6rem;
                }

                .as-icon {
                    position: relative;
                    top: -0.5rem;
                    margin-right: 2rem;
                    font-size: 6rem;
                }

                .as-label {
                    position: relative;
                    top: -1.4rem;

                    span {
                        display: block;
                        @include uppercased;

                        &.lighter {
                            font-size: 1.4rem;
                            line-height: 1.4rem;
                        }

                        &.bolded {
                            font-size: 1.8rem;
                            line-height: 1.8rem;
                        }
                    }
                }
            }

            .ahm-actions {
                margin-bottom: 3rem;
                text-align: center;

                .btn {
                    display: inline-block;
                    min-width: 26rem;
                    cursor: pointer;
                }
            }
        }
    }
}

/*------------------------------------*\
    CUSTOM MEDIA QUERIES
\*------------------------------------*/
@media (max-width: 320px) {
}

@media (max-width: 480px) {
}

@media (min-width: 480px) and (max-width: 979px) {
}

@media (max-width: 767px) {
}

@media (min-width: 767px) {
}

@media (max-width: 979px) {
}

@media (min-width: 979px) and (max-width: 1024px) {
}

@media (min-width: 979px) and (max-width: 1080px) {
}

@media (max-width: 1024px) {
}

@media (min-width: 1024px) and (max-width: 1200px) {
}

@media (max-width: 1200px) {
}

@media (min-width: 1200px) {
}

@media (min-width: 1200px) and (max-width: 1600px) {
}

@media (min-width: 1600px) and (max-width: 1800px) {
}

@media (min-width: 1600px) {
}

@media (max-width: 1600px) {
}

@media (max-width: 766px) {
}
