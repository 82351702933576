/*
 * @package    CNFPT
 * @author    <www.cnfpt.fr>
* @copyright  Copyright: (c) 2017 CNFPT
* @license    All right reserved.
*/

/*------------------------------------*\
    MIXINS
\*------------------------------------*/
@mixin slogan-title($_color) {
    position: absolute;
    display: inline-block;
    margin: 0 0 10px 0;
    padding: 10px;
    font-size: 4rem;
    font-weight: lighter;
    text-transform: uppercase;
    color: $_color;
    background: $third-color;
    z-index: 1;
    @include box-shadow(0 0 32px rgba(0, 0, 0, 0.8));

    &.slogan-title1 {
        top: 50px;
    }
    &.slogan-title2 {
        top: 147px;
    }
}

@mixin sidebar-title($_marg, $_pad, $_font, $_color, $_background, $_border_radius) {
    margin: $_marg;
    padding: $_pad;
    font-size: $_font;
    color: $_color;
    background: $_background;
    @include border-radius($_border_radius);
}

@mixin close-button-abs($i_font:null) {
    display: inline-block;

    @if $i_font != null {
        font-size: $i_font;
    }
    else {
        font-size: 1.6rem;
    }

    cursor: pointer;
    z-index: 10;
    @include transition-all(0.6s);

    &:hover, &:focus {
        transform: rotate(360deg);
    }
}

@mixin close-button-abs-alt($i_font:null) {
    @if $i_font != null {
        font-size: $i_font;
    }
    else {
        font-size: 1.6rem;
    }

    cursor: pointer;
    z-index: 10;
    @include transition-all(0.6s);

    &:hover, &:focus {
        transform: rotate(360deg);
    }
}

@mixin badge($_width, $_height, $pad_top, $_font, $_color, $_back) {
    display: block;
    width: $_width;
    height: $_height;

    padding-top: $pad_top;
    font-size: $_font;
    line-height: $_height;
    text-align: center;
    color: $_color;
    background: $_back;
    @include border-radius(100%);
    @include transition-all(0.6s);

    span {
        display: block;
    }
}

@mixin btn-iconic($_width, $_height, $pad_top, $_font, $i_font, $i_color) {
    width: $_width;
    height: $_height;
    font-size: $_font;
    line-height: $_font;
    text-align: center;

    span {
        display: block;
        float: left;
        width: 70%;
    }

    i {
        position: relative;
        float: left;
        max-width: 64px;
        margin-left: 10px;
        font-size: $i_font;
        color: $i_color;
    }
}

@mixin rounded-btn($height, $margin, $padding, $font, $fontweight, $fontstyle, $lineheight, $color, $background, $bordercolor, $colorhover, $bordercolorhover, $borderradius) {
    display: block;
    height: $height;
    margin: $margin;
    padding: $padding;
    line-height: $lineheight;
    text-align: center;
    text-transform: $fontstyle;
    font-weight: $fontweight;
    color: $color;
    background: $background;
    border: 1px solid $bordercolor;
    @include border-radius($borderradius);
    @include transition-all(0.6s);

    &:hover {
        color: $colorhover;
        background: transparent;
        border: 1px solid $bordercolorhover;
    }
}

@mixin rounded-two-lines-btn($height, $margin, $padding, $font, $fontweight, $fontstyle, $color, $background, $bordercolor, $colorhover, $bordercolorhover, $spanheight, $borderradius) {
    display: block;
    height: $height;
    margin: $margin;
    padding: $padding;
    text-align: center;
    text-transform: $fontstyle;
    font-weight: $fontweight;
    color: $color;
    background: $background;
    border: 1px solid $bordercolor;
    @include border-radius($borderradius);
    @include transition-all(0.6s);

    span {
        display: block;
        width: 100%;
        height: $spanheight;
        line-height: $spanheight;
        margin: 0;
    }

    &:hover {
        color: $colorhover;
        background: darken($background, 10%);
        border: 1px solid $bordercolorhover;
    }
}

@mixin btn-iconic-wide($_width, $_height, $pad_top, $_font, $i_font, $i_color, $content, $_span_b_left: null) {
    width: $_width;
    height: $_height;
    font-size: $_font;
    text-align: center;

    > span {
        position: relative;
        display: inline-block;
        margin: 0;
        font-size: $i_font;
        line-height: $_height - 24;

        //&:before {
        //  content: $content;
        //  display: inline-block;
        //  position: absolute;
        //  top: -2px;
        //  @if $_span_b_left  != null {
        //    left: $_span_b_left;
        //  } @else {
        //    left: -50px;
        //  }
        //  font-family: $icon-font;
        //  font-size: $i_font;
        //  color: $i_color;
        //}
    }

}

@mixin classed-btn-iconic-wide($_width, $_height, $pad_top, $_font, $_line_height, $i_font, $i_color, $content, $_class, $_span_b_left: null) {
    width: $_width;
    height: $_height;
    font-size: $_font;
    text-align: center;

    > span.button-icon-#{$_class} {
        position: relative;
        display: inline-block;
        line-height: $_line_height;
        margin: 0;

        &:before {
            content: $content;
            display: inline-block;
            position: absolute;
            top: -2px;
            @if $_span_b_left  != null {
                left: $_span_b_left;
            } @else {
                left: -50px;
            }
            font-family: $icon-font;
            font-size: $i_font;
            color: $i_color;
        }
    }

}

@mixin generate-btn-iconic-wide($_button-list) {
    @each $_width, $_height, $pad_top, $_font, $_line_height, $i_font, $i_color, $content, $_class, $_span_b_left in $_button-list {
        .btf-form-button-submit {
            margin: 20px auto;
            @include border-radius($_height/2);
            @include classed-btn-iconic-wide($_width, $_height, $pad_top, $_font, $_line_height, $i_font, $i_color, $content, $_class, $_span_b_left);
        }
    }
}

@mixin generate-btn-iconic-wide-link($_button-link-list) {
    @each $_width, $_height, $pad_top, $_font, $_line_height, $i_font, $i_color, $content, $_class, $_span_b_left in $_button-link-list {
        .btf-button-link {
            display: block;
            margin-top: 30px;
            line-height: inherit;
            @include classed-btn-iconic-wide($_width, $_height, $pad_top, $_font, $_line_height, $i_font, $i_color, $content, $_class, $_span_b_left);
        }
    }
}

@mixin btn-two-colors($_width, $_height, $_padding, $_span_width, $_font, $_i_font, $_color, $_background_first, $_background_second, $_top, $_i_left) {
    @extend .btn;

    width: $_width;
    height: $_height;
    padding: 0;
    @include lighter;
    @include uppercased;
    background: $_background_first url('./../images/footer-bkg.png') repeat-x top left;
    border: 0px;
    @include transition-all(0.6s);
    @include border-radius(3px);

    &:hover, &:focus {
        background: $third-color;
    }

    span {
        position: relative;
        display: block;
        float: right;
        width: $_span_width;
        height: $_height;
        margin: 0;
        padding: $_padding;
        font-size: $_font;
        text-align: center;
        line-height: $_height;
        color: #ffffff;
        background: $_background_second;
        z-index: 2;
        @include border-radius(0 3px 3px 0);

        i {
            position: relative;
            top: $_top;
            left: $_i_left;
            font-size: $_i_font;
            color: lighten($_background_first, 50%);
        }

        &:before {
            position: absolute;
            left: -25px;
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 25px 60px 25px;
            border-color: transparent transparent $_background_second transparent;
            z-index: 3;
            content: "";
        }
    }
}

@mixin circled-number($_width, $_height, $_font, $_color, $_back) {
    display: block;
    width: $_width;
    height: $_height;
    padding-top: 12px;
    font-size: $_font;
    line-height: $_font;
    @include lighter;
    text-align: center;
    color: $_color;
    background: $_back;
    @include border-radius(100%);
    @include transition-all(0.6s);
}

@mixin rec-badge($_top, $_left, $_width, $_height, $_font, $_color, $_back, $triangle_color) {
    position: absolute;
    top: $_top;
    left: $_left;
    display: inline-block;
    min-width: $_width;
    height: $_height;
    padding: 0 10px;
    font-size: $_font;
    line-height: $_height;
    @include lighter;
    @include uppercased;
    text-align: center;
    color: $_color;
    background: $_back;
    @include transition-all(0.6s);
    @include box-shadow(5px 5px 6px 0px rgba(0, 0, 0, 0.5));
    z-index: 10;

    &:after {
        content: "";
        display: block;
        position: absolute;
        bottom: -8px;
        left: 0;
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 0 8px 8px 0;
        border-color: transparent $triangle_color transparent transparent;
        z-index: 11;
    }
}

@mixin rec-badge-top($_width, $_height, $_font, $_color, $_back, $triangle_color, $_left) {
    position: absolute;
    top: -10px;
    right: $_left;
    display: inline-block;
    min-width: $_width;
    width: auto;
    height: $_height;
    padding: 0 10px;
    font-size: $_font;
    line-height: $_height;
    @include lighter;
    @include uppercased;
    text-align: center;
    color: $_color;
    background: $_back;
    @include transition-all(0.6s);
    @include box-shadow(1px 3px 4px 0 rgba(0, 0, 0, 0.3));
    z-index: 10;

    &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: -10px;
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 0 0 10px 10px;
        border-color: transparent transparent $triangle_color transparent;
        z-index: 11;
    }
}

@mixin rec_top_right_badge($_top, $_right, $_width, $_height, $_line_height, $_font_weight, $_color, $_background, $_border_radius, $_box_shadow, $_before_left, $_before_border_width, $_before_border_color) {
    position: absolute;
    top: $_top;
    right: $_right;
    width: $_width;
    height: $_height;
    padding: 0 1.5rem;
    line-height: $_line_height;
    font-weight: $_font_weight;
    color: $_color;
    background: $_background;
    @include border-radius($_border_radius);
    @include box-shadow($_box_shadow);
    z-index: 10;

    &:before {
        position: absolute;
        top: 0;
        left: $_before_left;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: $_before_border_width;
        border-color: $_before_border_color;
        content: "";
        z-index: 10;
    }
}

@mixin rec_top_left_badge($_top, $_width, $_height, $_line_height, $_font_weight, $_color, $_background, $_border_radius, $_box_shadow, $_before_left, $_before_border_width, $_before_border_color) {
    position: absolute;
    top: $_top;
    width: $_width;
    height: $_height;
    padding: 0 1.5rem;
    line-height: $_line_height;
    font-weight: $_font_weight;
    color: $_color;
    background: $_background;
    @include border-radius($_border_radius);
    @include box-shadow($_box_shadow);
    z-index: 10;

    &:before {
        position: absolute;
        top: 0;
        left: $_before_left;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: $_before_border_width;
        border-color: $_before_border_color;
        content: "";
        z-index: 10;
    }
}

/**
Make an oblic rectangle badge
*/
@mixin oblic-rec-badge-top($_top, $_width, $_height, $_font, $_color, $_back, $tri_right, $tri_size, $tri_color, $_i_tri_color, $_i_back, $_left, $_i_top, $_i_font, $_i_color, $_iconless:no) {
    position: absolute;
    top: $_top;
    left: $_left;
    display: inline-block;
    min-width: $_width;
    width: auto;
    height: $_height;
    padding: 0;
    font-size: $_font;
    line-height: $_height;
    @include lighter;
    @include uppercased;
    text-align: center;
    color: $_color;
    background: $_back;
    @include transition-all(0.6s);
    @include box-shadow(8px 15px 13px -7px rgba(0, 0, 0, 0.5));
    z-index: 10;
    @include border-radius(5px 0 0 0);

    &:after {
        position: absolute;
        top: 0;
        right: $tri_right;
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: $tri_size;
        border-color: $tri_color transparent transparent transparent;
        z-index: 3;
        content: "";
    }

    .orbt-label {
        position: relative;
        margin-left: 10px;
        z-index: 4;
    }

    @if $_iconless  == yes {
        .orbt-icon {
            position: relative;
            display: block;
            float: left;
            width: $_height;
            height: $_height;

            background: $_i_back;
            z-index: 3;

            &:after {
                position: absolute;
                top: 0;
                right: $tri_right;
                display: block;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: $tri_size;
                border-color: $_i_tri_color transparent transparent transparent;
                z-index: 3;
                content: "";
            }

            i {
                position: relative;
                top: $_i_top;
                font-size: $_i_font;
                color: $_i_color;
                z-index: 4;
            }
        }
    }
}

@mixin circle-iconic($_width, $_height, $i_font, $_color, $_back, $_content) {
    display: block;
    width: $_width;
    height: $_height;
    line-height: $_height - 0.3;
    text-align: center;
    background: $_back;
    @include border-radius(100%);
    @include transition-all(0.6s);
    @include box-shadow(0.4rem 0.4rem 0.5rem 0px rgba(0, 0, 0, 0.2));
    z-index: 10;

    &:hover, &:focus {
        @include box-shadow(none);
    }

    &:before {
        content: $_content;
        display: inline-block;
        position: relative;
        /*top: 2px;*/
        font-family: $icon-font;
        font-size: $i_font;
        color: $_color;
    }
}

@mixin circle-iconic-link($_width, $_height, $i_font, $_color, $_back, $_pattern : false) {
    display: block;
    width: $_width;
    height: $_height;
    text-align: center;
    line-height: $_height;
    color: $_color;

    @if $_pattern == true {
        background: $_back url('./../images/footer-bkg.png') repeat-x top left;
    } @else if $_pattern == false {
        background-color: $_back;
    }
    border: 2px solid $_back;

    @include border-radius(100%);
    @include transition-all(0.6s);
    @include box-shadow(5px 5px 6px 0px rgbacircle-iconic(0, 0, 0, 0.5));
    z-index: 10;

    &:hover, &:focus {
        @if $_pattern == true {
            background: darken($_back, 20%) url('./../images/footer-bkg.png') repeat-x top left;
        } @else if $_pattern == false {
            background: darken($_back, 20%);
        }
        border: 2px solid darken($_back, 20%);
        @include box-shadow(none);
    }

    i {
        position: relative;
        /*top: 4px;*/
        font-size: $i_font;
        line-height: $_height - 5;
        color: $_color;
    }
}

/**

 */
@mixin inline-iconic-link($_lineheight, $_color, $_text_marl, $_text_bb, $_icons) {
    position: relative;
    line-height: $_lineheight;
    color: $_color;

    .aed-icon {
        position: absolute;

        @each $_icon, $_icon_top, $_icon_marr, $_icon_size in $_icons {
            &.#{$_icon} {
                top: $_icon_top;
                margin-right: $_icon_marr;
                font-size: $_icon_size;
            }
        }
    }

    .pm-link-text {
        position: relative;
        margin-left: $_text_marl;
        border-bottom: 1px solid $_text_bb;
    }
}

@mixin inline-iconic-link2($_mar_l, $_lineheight, $_color, $_text_bb, $_icon_top, $_icon_left, $_icon, $_icon_size) {
    position: relative;
    margin-left: $_mar_l;
    line-height: $_lineheight;
    color: $_color;
    border-bottom: 1px solid $_text_bb;

    &:before {
        position: absolute;
        top: $_icon_top;
        left: $_icon_left;
        display: inline-block;
        width: 30px;
        height: 30px;
        font-family: $icon-font;
        font-size: $_icon_size;
        //line-height: 30px;
        content: $_icon;
        color: $_color;
        z-index: 1;
    }
}

@mixin square-iconic($_width, $_height, $i_font, $_color, $_back, $_content) {
    display: block;
    width: $_width;
    height: $_height;
    text-align: center;
    background: $_back;
    /*@include  border-radius(3px);*/
    @include transition-all(0.6s);
    /*@include  box-shadow(5px 5px 6px 0px rgba(0, 0, 0, 0.5));*/
    z-index: 10;

    &:hover, &:focus {
        @include box-shadow(none);
    }

    &:before {
        content: $_content;
        display: inline-block;
        position: relative;
        top: 2px;
        font-family: $icon-font;
        font-size: $i_font;
        color: $_color;
    }
}

@mixin custom-list-style($left, $mar_left, $font, $icon_size, $passive_icon, $passive_color, $hover_icon, $hover_color) {
    &:before {
        content: $passive_icon;
        position: relative;
        top: 0;
        left: $left;
        margin-left: $mar_left;
        font-family: $font;
        font-size: $icon_size;
        text-indent: 0;
        color: $passive_color;
        @include transition-all(0.6s);
    }
    &:hover:before, &:focus:before {
        content: $hover_icon;
        color: $hover_color;
        @include transition-all(0.6s);
    }
}

@mixin generate-custom-list-style($pad_top, $pad_left, $li_mar_bot, $li_pad_left, $li_font_size, $a_color, $a_hover_color, $left, $mar_left, $font, $icon_size, $passive_icon, $passive_color, $hover_icon, $hover_color, $_font_weight: null) {
    ul.list-unstyled {
        padding-top: $pad_top;
        padding-left: $pad_left;
        @extend .list-unstyled;

        li {
            margin-bottom: $li_mar_bot;
            padding-left: $li_pad_left;
            font-size: $li_font_size;
            @include lighter;

            @include custom-list-style($left, $mar_left, $font, $icon_size, $passive_icon, $passive_color, $hover_icon, $hover_color);

            a {
                @if $_font_weight {
                    font-weight: $_font_weight;
                }

                color: $a_color;

                &:hover, &:focus {
                    color: $a_hover_color;
                }
            }

            &.active {
                @include custom-list-style($left, $mar_left, $font, $icon_size, $passive_icon, $hover_color, $hover_icon, $hover_color);

                a {
                    color: $a_hover_color;
                }

            }
        }

    }
}

@mixin ajax-loader($_width, $_height, $_margin) {
    .ajax-loader {
        position: absolute;
        top: 50%;
        left: 50%;
        width: $_width;
        height: $_height;
        margin: $_margin;
    }
}

@mixin open-close-btn($_back_color, $_border_color) {
    display: inline;
    position: absolute;
    min-width: 30px;
    outline: none;
    z-index: 10;

    > span {
        float: left;
        font-size: 1.4rem;
    }

    &:hover, &:focus, &:active {
        background: $_back_color;
        border-color: $_border_color;
    }

    i {
        display: inline-block;
        position: relative;
        top: 0;
        float: left;
        font-size: 2.8rem;
        line-height: 2.4rem;
        @include transition-all(0.6s);
        transform: rotate(180deg);
    }

    &.opened {
        i {
            top: 0;
            transform: rotate(0);
        }
    }
}

@mixin scroll-down-btn($_inner_color, $_wheel_color) {
    width: 46px;
    height: 70px;
    margin-left: -23px;
    z-index: 202;
    animation: bounce 1.5s steps(5, start) infinite;
    cursor: pointer;

    .scroll-mouse-inner {
        position: absolute;
        top: 3px;
        left: 3px;
        width: 40px;
        height: 64px;
        background: $_inner_color;
        z-index: 1;

        @include border-radius(20px);

        i {
            position: absolute;
            &.wheel {
                top: 11px;
                left: 16px;
                width: 8px;
                height: 18px;
                background: $_wheel_color;
                @include border-radius(5px);
            }
            &.fa-apple {
                bottom: 5px;
                left: 12px;
                font-size: 1.9rem;
                color: $third-color;
            }
        }
    }

    .ring {
        position: absolute;
        top: 0;
        left: 0;
        width: 46px;
        height: 70px;
        margin: 0;
        background: darken($_inner_color, 20%);
        @include border-radius(20px);
        @include box-shadow(0 2px 5px rgba(0, 0, 0, .5));
        @include animation(ring 1.1s ease-out infinite);
    }
    &:hover, &:focus {
        @include animation(none !important);
    }
}

@mixin spanned-icon($_icon, $_size, $_color, $_back, $_right, $_bottom) {
    &:after {
        position: absolute;
        right: $_right;
        bottom: $_bottom;
        width: 30px;
        height: 30px;
        font-size: $_size;
        line-height: 30px;
        content: $_icon;
        color: $_color;
        background: $_back;
        @include border-radius(100%);
        z-index: 2;
    }
}

@mixin generate-spanned-icon($_icon-list) {
    @each $_name, $_code, $_right, $_bottom in $_icon-list {
        i {
            span.spanned-btf-icon-#{$_name} {
                @include spanned-icon($_code, 0.875, #ffffff, $nineth-color, $_right, $_bottom);
            }
        }
    }
}

@mixin rectangle-colored-text-block($_margin_b, $_padding, $_font_size, $_font_weight, $_color, $_background, $_border_radius, $_pattern: false ) {
    margin-bottom: $_margin_b;
    padding: $_padding;
    font-size: $_font_size;
    line-height: $_font_size;
    font-weight: $_font_weight;
    color: $_color;

    @if $_pattern == true {
        background: $_background url('./../images/footer-bkg.png') repeat-x top left;
    } @else if $_pattern == false {
        background: $_background;
    }

    @include border-radius($_border_radius);
}

@mixin rounded-rectangle-elt($_padding, $_font_size, $_font_weight, $_color, $_background, $_color_hover, $_background_hover, $_border_color, $_border_radius) {
    padding: $_padding;
    font-size: $_font_size;
    font-weight: $_font_weight;
    color: $_color;
    background: $_background;
    border: 1px solid $_border_color;
    @include border-radius($_border_radius);
    @include transition-all(0.6s);

    &:hover, &:focus {
        color: $_color_hover;
        background: $_background_hover;
        border: 1px solid $_background_hover;
    }
}

/**
Follow the structure below :
  <div class="empty-block">
      <div class="inner">
          <p>
             message
          </p>
      </div>
  </div>
 */
@mixin empty-block($icon-size, $icon, $top: -0.4rem) {
    .inner {
        padding: 1.5rem;
        background: #f6f6f6;
        border: 0.1rem solid #dedede;

        p {
            margin: 0;
            color: #555454;

            &:before {
                position: relative;
                top: $top;
                float: left;
                display: inline-block;
                width: 3rem;
                height: 3rem;
                margin-right: 2rem;
                font-size: $icon-size;
                font-family: $icon-font;
                line-height: 3rem;
                text-align: center;
                color: #ffffff;
                content: $icon;
                background: #9f2042;
                @include border-radius(100%);
            }
        }
    }
}

//Generate padding and margin from 1 to 100px css classes
@mixin generate-spacing($classSlug, $property) {
    @for $i from 1 through 100 {
        .#{$classSlug}-#{$i} {
            #{$property}: $i + px;
        }
    }
}

//Generate no paddinfg or no margin css classes
$properties: (mar, margin), (pad, padding);

@mixin no-spacing($sideLabel, $side, $value) {
    @each $propLabel, $prop in $properties {

        .no-#{$propLabel}-#{$sideLabel} {
            #{$prop}-#{$side}: $value;
        }
    }
}

//Arrow button
@mixin arrow-btn($padtop, $padright, $padbottom, $padleft, $fontsize, $color, $icon, $iconsize) {
    padding-right: $padright;
    padding-left: $padleft;
    padding: $padtop $padright $padbottom $padleft;
    font-size: $fontsize;
    color: $color !important;
    border: 0 none;
    overflow: hidden;

    &:before {
        position: absolute;
        top: 50%;
        left: -2.5rem;
        display: inline-block;
        width: 5rem;
        height: 5rem;
        margin-top: -2.5rem;
        background: transparent;
        border: 0.3rem solid $color;
        @include transformize(rotate(45deg));
        @include border-radius(0.5rem);
        content: "";
        z-index: 2;
    }
}

//Views / Listing loader
@mixin views-listing-loader() {
    position: relative;
    display: block;
    width: 10rem;
    height: 10rem;
    margin: 3rem auto;
    line-height: 8.56rem;
    font-size: 1rem;
    @include uppercased;
    color: $primary-color;
    background: transparent;
    border: 0 none;

    &:hover, &:focus {
        background: transparent;
    }

    &:before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 10rem;
        height: 10rem;
        @include uppercased;
        content: '';
        background: url('../../images/loading.svg') no-repeat center center;
        @include transition-all(0.6s);

        -webkit-animation: kc-circle-rotation 1s linear infinite;
        -moz-animation: kc-circle-rotation 1s linear infinite;
        -ms-animation: kc-circle-rotation 1s linear infinite;
        -o-animation: kc-circle-rotation 1s linear infinite;
        animation: kc-circle-rotation 1s linear infinite;
    }

    img {
        display: none !important;
    }
}

@mixin inner-block($padding) {
    padding: $padding;
    background: #f6f6f6;
    border: 0.1rem solid #dedede;
}

@mixin custom-dropdown-select() {

    &.open, &.show {
        .dropdown-toggle {
            color: #ffffff;
            background: $primary-color;
            border-color: transparent;
            &:after {
                color: #ffffff;
                @include transformize(rotate(180deg));
            }
        }
    }

    &.cds-single {
        .dropdown-menu {
            li {
                a {
                    input {
                        display: none;
                    }
                }
            }
        }
    }

    .dropdown-toggle {
        position: relative;
        text-align: left;
        color: $body-color;
        overflow: hidden;

        &:after {
            position: absolute;
            top: 50%;
            right: 1.5rem;
            display: inline-block;
            width: 1.4rem;
            height: 1.4rem;
            margin-top: -0.7rem;
            margin-left: inherit;
            font-family: $icon-font;
            line-height: 1.4rem;
            content: "\e90d";
            color: $body-color;
            border: 0 none;
            @include transition-all(0.6s);
        }
    }

    .dropdown-menu {
        min-width: 100%;
        @include border-radius(0rem);
        @include box-shadow(1px 4px 18px -9px rgba(0, 0, 0, 0.75));

        li {
            padding: 1rem 1.5rem;
            font-size: 1.3rem;
            @include uppercased;
            color: #767676;
            background: #e4e4e4;
            border-bottom: 0.1rem solid #ffffff;
            white-space: normal !important;

            &.dropdown-divider {
                display: none;
            }

            &.dropdown-item {
                &.active {
                    background: #f2f2f2!important;
                }
            }

            a {
                @include bolded;
                color: #767676;

                input {
                    position: relative;
                    top: 0.1rem;
                    margin-right: 1rem;
                }
            }

            .input-group, .input-group-sm {
                .input-group-addon {
                    padding: 0.5rem 0 !important;
                }
            }

            .input-group, .input-group-sm {
                > .form-control {
                    font-size: 1.3rem !important;
                }
            }

            .input-group, .input-group-sm {
                > .input-group-btn {
                    > .btn {
                        min-width: 4rem;
                        padding: 0.5rem 0 !important;
                        font-size: 2rem !important;
                        //line-height: 4rem;
                        background: $primary-color;

                        i {
                            &:before {
                                display: inline-block;
                                min-width: 4rem;
                                font-family: $icon-font;
                                color: #ffffff;
                                content: "\e921";
                            }
                        }
                    }
                }
            }
        }
    }
}

@mixin custom-dropdown-select-1_8() {

    &.open, &.show {
        .dropdown-toggle {
            color: #ffffff;
            background: $primary-color;
            border-color: transparent;
            &:after {
                color: #ffffff;
                @include transformize(rotate(180deg));
            }
        }
    }

    &.cds-single {
        .dropdown-menu {
            li {
                a {
                    input {
                        display: none;
                    }
                }
            }
        }
    }

    .dropdown-toggle {
        position: relative;
        text-align: left;
        color: $body-color;
        overflow: hidden;

        &:after {
            position: absolute;
            top: 50%;
            right: 1.5rem;
            display: inline-block;
            width: 1.4rem;
            height: 1.4rem;
            margin-top: -0.7rem;
            margin-left: inherit;
            font-family: $icon-font;
            line-height: 1.4rem;
            content: "\e90d";
            color: $body-color;
            border: 0 none;
            @include transition-all(0.6s);
        }
    }

    .dropdown-menu {
        min-width: 100%;
        @include border-radius(0rem);
        @include box-shadow(1px 4px 18px -9px rgba(0, 0, 0, 0.75));

        .dropdown-divider {
            display: none;
        }

        .dropdown-item {
            padding: 1rem 1.5rem;
            font-size: 1.3rem;
            @include uppercased;
            color: #767676;
            background: #e4e4e4;
            border-bottom: 0.1rem solid #ffffff;
            white-space: normal !important;

            &.active {
                background: #f2f2f2!important;
            }
        }

        a {
            @include bolded;
            color: #767676;

            input {
                position: relative;
                top: 0.1rem;
                margin-right: 1rem;
            }
        }

        .input-group, .input-group-sm {
            > .input-group-prepend {
                > .input-group-text {
                    padding: 0.5rem 0 !important;
                }
                > .form-control {
                    font-size: 1.3rem !important;
                }
            }
            > .input-group-append {
                > .btn {
                    min-width: 4rem;
                    padding: 0.5rem 0 !important;
                    font-size: 2rem !important;
                    //line-height: 4rem;
                    background: $primary-color;

                    i {
                        &:before {
                            display: inline-block;
                            min-width: 4rem;
                            font-family: $icon-font;
                            color: #ffffff;
                            content: "\e921";
                        }
                    }
                }
            }
        }
    }
}

@mixin as-social() {
    nav {
        a {
            float: left;
            margin-right: 1rem;
            border: 0.2rem solid $third-color;

            &.as-facebook {
                @include circle-iconic(3.2rem, 3.2rem, 1.4rem, $third-color, #f6f6f6, "\e911");
            }

            &.as-twitter {
                @include circle-iconic(3.2rem, 3.2rem, 1.4rem, $third-color, #f6f6f6, "\e926");
            }

            &.as-viadeo {
                @include circle-iconic(3.2rem, 3.2rem, 1.4rem, $third-color, #f6f6f6, "\e934");
            }

            &.as-linkedin {
                @include circle-iconic(3.2rem, 3.2rem, 1.4rem, $third-color, #f6f6f6, "\e918");
            }

            &.as-tumblr {
                @include circle-iconic(3.2rem, 3.2rem, 1.4rem, $third-color, #f6f6f6, "\e937");
            }

            &.as-cv, &.as-pdf, &.as-html, &.as-printer {
                position: relative;
                top: -0.2rem;
                font-size: 2.8rem;
                color: $third-color;
                border: none 0;

                .aed-icon {
                    font-size: 2.8rem !important;
                }
            }
        }
    }
}