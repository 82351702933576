/*
 * @package    CNFPT
 * @project      AED
 * @author    <www.cnfpt.fr>
* @copyright  Copyright: (c) 2017 CNFPT
* @license    All right reserved.
*/

//CNFPT PROJECT CUSTOMIZATIOn
$primary:        #a7145a;
$secondary:       darken(#a7145a, 20%);

$primary-color:        #a7145a;
$secondary-color:       darken(#a7145a, 20%);
$third-color:           #323a45;
$fourth-color:           #888886;
$fifth-color:           #86909d;
$sixth-color:           #4d475f;
$seventh-color:       #653e48;
$eighth-color:       #7895e8;
$nineth-color:       #00acac;
$tenth-color:       #b6c2c9;
$eleven-color:       #8f6c7c;
$gray-color: #ececec;
$gray-light-color: #767676;

$info-alt:            #0099e2;
$alert-default-bg:             $third-color;
$alert-default-text:           #ffffff;
$alert-default-border:        $third-color;

$success:      $nineth-color;
$info:          $info-alt;
$warning:       #f0ad4e;
$danger:        #e44458 ;

//$theme-colors: () !default;
//$theme-colors: map-merge((
//    "primary":    $primary,
//    "secondary":  $secondary,
//    "success":    $nineth-color,
//    "info":       $info-alt,
//    "warning":    $warning,
//    "danger":     $danger,
//    "light":      $light,
//    "dark":       $dark
//), $theme-colors);

// Body
//
// Settings for the `<body>` element.

$body-bg:                   #ffffff;
$body-color:                #555454;

// Links
//
// Style anchor elements.

$link-color:                $primary-color;
$link-hover-color:          darken($link-color, 15%);
$link-hover-decoration:     underline;


// Fonts
$font-family-sans-serif:  "Open Sans", Helvetica, Arial, sans-serif;
$font-size-base:              1rem;

$h1-font-size:                $font-size-base * 2.8;
$h2-font-size:                $font-size-base * 2.2;
$h3-font-size:                $font-size-base * 1.6;
$h4-font-size:                $font-size-base * 1.2;
$h5-font-size:                $font-size-base * 1;
$h6-font-size:                $font-size-base * 0.8;

// Buttons
//
// For each of Bootstrap's buttons, define text, background and border color.

$input-btn-padding-y:         0.8rem;
$input-btn-padding-x:         2rem;
$input-btn-line-height:       1.5;

// Forms
$input-bg:                             #f4f4f4;

$input-color:                           #555454;
$input-border-color:                   #f4f4f4;
//$input-box-shadow:                      inset 0 1px 1px rgba($black,.075) !default;

$input-focus-bg:                        $input-bg;
$input-focus-border-color:              $info-alt;
$input-focus-color:                     $input-color;

$input-height:                         4rem;

//Buttons

$input-btn-padding-y-sm:      .5rem;
$input-btn-padding-x-sm:     1rem;
$input-btn-line-height-sm:   1.5;

$btn-secondary-color:              #ffffff;
$btn-secondary-bg:                 $secondary-color;
$btn-secondary-border:          $secondary-color;

$btn-eleventh-color:              #ffffff;
$btn-eleventh-bg:                 $eleven-color;
$btn-eleventh-border:          $eleven-color;

$btn-third-color:              #ffffff;
$btn-third-bg:                 $third-color;
$btn-third-border:          $third-color;

$btn-gray-color:              #333333;
$btn-gray-bg:                 #dedede;
$btn-gray-border:           #dedede;

// Navs

$nav-link-padding-y:                0;
$nav-link-padding-x:                1.5rem;
//$nav-link-disabled-color:           $gray-600;

// Navbar
$navbar-padding-y:                  1.5rem;
$nav-link-height:                  1;

$navbar-light-color:                #d2d2d2;
$navbar-light-hover-color:          $primary-color;
$navbar-light-active-color:         $primary-color;
$navbar-light-toggler-border-color: #dedede;

// Pagination

$pagination-padding-y:              .5rem !default;
$pagination-padding-x:              1rem;
$pagination-padding-y-sm:           .25rem !default;
$pagination-padding-x-sm:           .5rem !default;
$pagination-padding-y-lg:           .75rem !default;
$pagination-padding-x-lg:           1.5rem !default;
$pagination-line-height:            1.25 !default;

$pagination-color:                  $third-color;
$pagination-bg:                     #ffffff;
$pagination-border-width:           0.1rem;
$pagination-border-color:           #dedede;

$pagination-hover-color:            #ffffff;
$pagination-hover-bg:               $primary-color;
$pagination-hover-border-color:     $primary-color;

//$pagination-active-color:           $white !default;
//$pagination-active-bg:              theme-color("primary") !default;
//$pagination-active-border-color:    theme-color("primary") !default;
//
//$pagination-disabled-color:         $gray-600 !default;
//$pagination-disabled-bg:            $white !default;
//$pagination-disabled-border-color:  #ddd !default;

// Tooltips

$tooltip-max-width:                 36rem;
$tooltip-bg:                       $third-color;
$tooltip-opacity:                   1;
$tooltip-padding-y:                 1rem;
$tooltip-padding-x:                 1.5rem;
$tooltip-margin:                    0;

//***********************************************************//
/**  ICONS VARS
//***********************************************************/
$icon-font: 'aed';

$icomoon-font-path: "/assets/fonts";
$icomoon-font-family: "aed" !default;

$aed-icon-calendar: "\e939";
$aed-icon-eye-crossed: "\e938";
$aed-icon-pdf: "\e935";
$aed-icon-html: "\e936";
$aed-icon-tumblr: "\e937";
$aed-icon-viadeo: "\e934";
$aed-icon-back: "\e933";
$aed-icon-stop: "\e932";
$aed-icon-feather: "\e931";
$aed-icon-cv: "\e930";
$aed-icon-edit: "\e92c";
$aed-icon-smartphone: "\e92f";
$aed-icon-rewind: "\e92d";
$aed-icon-fast-forward: "\e92e";
$aed-icon-power: "\e92b";
$aed-icon-info: "\e92a";
$aed-icon-play: "\e900";
$aed-icon-play-full: "\e901";
$aed-icon-square: "\e902";
$aed-icon-square-full: "\e903";
$aed-icon-add: "\e904";
$aed-icon-at: "\e905";
$aed-icon-bookmark: "\e906";
$aed-icon-briefcase: "\e907";
$aed-icon-check: "\e908";
$aed-icon-circle: "\e909";
$aed-icon-clock: "\e90a";
$aed-icon-close: "\e90b";
$aed-icon-contrast: "\e90c";
$aed-icon-down-arrow: "\e90d";
$aed-icon-download: "\e90e";
$aed-icon-email: "\e90f";
$aed-icon-eye: "\e910";
$aed-icon-facebook: "\e911";
$aed-icon-family-tree: "\e912";
$aed-icon-folder: "\e913";
$aed-icon-hash: "\e914";
$aed-icon-heart: "\e915";
$aed-icon-home: "\e916";
$aed-icon-left-arrow: "\e917";
$aed-icon-linkedin: "\e918";
$aed-icon-locked: "\e919";
$aed-icon-minus: "\e91a";
$aed-icon-phone: "\e91b";
$aed-icon-placeholder: "\e91c";
$aed-icon-printer: "\e91d";
$aed-icon-right-arrow: "\e91e";
$aed-icon-rotate: "\e91f";
$aed-icon-rss: "\e920";
$aed-icon-search: "\e921";
$aed-icon-settings: "\e922";
$aed-icon-star: "\e923";
$aed-icon-tag: "\e924";
$aed-icon-trash: "\e925";
$aed-icon-twitter: "\e926";
$aed-icon-up-arrow: "\e927";
$aed-icon-user: "\e928";
$aed-icon-worldwide: "\e929";
