/*
 * @package    CNFPT
 * @project      AED
 * @author    <www.cnfpt.fr>
* @copyright  Copyright: (c) 2017 CNFPT
* @license    All right reserved.
*/

/*------------------------------------*\
    ASSIGNATION
\*------------------------------------*/
.affectation-history {
  .inner {
    margin-top: 1.5rem;
    padding: 2rem 0 0 0!important;
    border-top: 0.1rem solid #dedede;
  }
}