/*
 * @package    CNFPT
 * @project      AED
 * @author    <www.cnfpt.fr>
* @copyright  Copyright: (c) 2017 CNFPT
* @license    All right reserved.
*/

/*------------------------------------*\
    AGENT
\*------------------------------------*/

body {
  &.contrast-active {
    .agent-detail {
      .agent-detail-header {

        .inner {
          background: #000;

          .adh-thumb-infos {
            .adh-infos {
              .adh-infos-title-link {

                .adh-infos-link {
                  color: #fff;

                  .aed-icon {
                    color: #fff;
                  }
                }
              }
            }
          }

          .adh-mobility {
            .am-inner {
              border-right: 0.1rem solid #fff;
              border-left: 0.1rem solid #fff;

              &:before {
                color: $primary-color;
              }
            }
          }

          .adh-social {
            nav {
              a {
                float: left;
                margin-right: 1rem;
                border: 0.2rem solid #fff;

                &.as-facebook {
                  @include circle-iconic(3.2rem, 3.2rem, 1.4rem, #fff, #000, "\e911");
                }

                &.as-twitter {
                  @include circle-iconic(3.2rem, 3.2rem, 1.4rem, #fff, #000, "\e926");
                }

                &.as-cv {
                  color: #fff;
                  border: 0 none;
                }
              }
            }
          }
        }
      }

      .agent-training-elt {
        .ate-title {
          color: $primary-color;
        }

        .ate-place {
          color: $body-color;
        }

        .ate-date {
          color: $body-color;
        }
      }
    }
  }
}

.competences-chips {
  .cc-elt {
    display: inline-flex;
    min-height: 4rem;
    margin: 0 1.5rem 1.5rem 0;

    .btn {
      cursor: default;
      min-width: 5rem;
      padding: 0.8rem 1rem 0.8rem 1rem;
      background-color: #f0f0f0;
      border-color: #dedede;
      @include border-radius(2rem);
    }

    .ce-label {
      margin-right: 1.5rem;
    }


    .ce-badge {
      cursor: pointer;
      width: 2.2rem;
      height: 2.2rem;
      line-height: 1.8rem;
      background-color: #696969;
      @include border-radius(100%);

      .aed-icon {
        font-size: 1rem;
        color: $white;
      }
    }
  }
}

.agent-detail {
  .agent-detail-header {
    margin-bottom: 4rem;

    .inner {
      min-height: 14rem;
      @include inner-block(2rem 1rem);

      .adh-thumb-infos {

        .adh-thumb {
          text-align: center;

          img {
            margin: 0 auto;
          }
        }

        .adh-infos {
          position: relative;

          .adh-infos-title-link {
            margin: 0 0 1rem 0;

            .adh-infos-link {
              font-size: 1.3rem;
              @include bolded;
              @include uppercased;
              color: $body-color;

              .aed-icon {
                position: relative;
                top: 0.3rem;
                font-size: 2rem;
                color: $primary-color;
              }
            }
          }
        }
      }

      .adh-status-mobility {
        .asm-inner {
          min-height: 11rem;
          //padding-right: 1.5rem;
          //padding-left: 1.5rem;
          border-right: 0.1rem solid #dedede;
          border-left: 0.1rem solid #dedede;
        }

        .asm-status {
          margin-bottom: 2rem;

          .as-inner {
            position: relative;
            min-height: 6rem;
            padding-left: 4.5rem;
            border-bottom: 0.1rem solid #dedede;

            //&:before {
            //  position: absolute;
            //  left: 0;
            //  top: 0;
            //  width: 2.6rem;
            //  height: 3.6rem;
            //  color: $primary-color;
            //  font-family: $icon-font;
            //  font-size: 3rem;
            //  content: "\e91c";
            //}

            &:before {
              position: absolute;
              left: 0;
              top: 0;
              display: inline-block;
              width: 3rem;
              height: 3rem;
              font-size: 2rem;
              font-family: $icon-font;
              line-height: 3rem;
              text-align: center;
              color: #fff;
              content: "\e92a";
              background: #9f2042;
              @include border-radius(100%);
            }

            span {
              display: block;
            }
          }
        }

        .asm-mobility {
          .am-inner {
            position: relative;
            min-height: 11rem;
            padding-left: 4.5rem;

            &:before {
              position: absolute;
              left: 0;
              top: 0;
              width: 2.6rem;
              height: 3.6rem;
              color: $primary-color;
              font-family: $icon-font;
              font-size: 3rem;
              content: "\e91c";
            }

            span {
              display: block;
            }
          }
        }
      }

      .adh-social {
        border-left: 0.1rem solid #dedede;
        @include as-social();
      }

      .adh-bio {
        .ab-inner {
          position: relative;
          margin: 1.5rem 0 0 0;
          padding: 1.5rem 0 0 4rem;
          font-size: 1.3rem;
          border-top: 0.1rem solid #dedede;

          .aed-icon {
            position: absolute;
            left: 0;
            font-size: 3rem;
          }
        }
      }
    }
  }

  .card-actions {
    margin: 1rem 0!important;

    .arrow-btn.btn-sm, .btn-group-sm > .arrow-btn.btn {
      padding: 0.6rem 2rem 0.6rem 4.5rem;
      font-size: 1.3rem;
    }
  }

  .agent-training-elt {
    margin-bottom: 1.5rem;

    .ate-title {
      font-size: 1.6rem;
      color: $primary-color;
    }

    .ate-place {
      font-size: 1.3rem;
      color: $body-color;
    }

    .ate-title, .ate-place {
      @include bolded;
      @include uppercased;
    }

    .ate-date {
      font-size: 1.3rem;
      color: $body-color;
    }

    .ate-visibility {
      font-size: 1.3rem;
      color: $body-color;
    }

    .ate-missions {
      margin-top: 1rem;
      font-size: 1.3rem;
      color: $body-color;
    }

    .ate-title, .ate-place, .ate-date, .ate-visibility {
      display: block;
    }
  }

  .aed-accordion {
    .card {
      .card-elt {
        .as-social {
          @include as-social();
        }
      }
    }

  }

  &.aed-form-edition {
    .user-cv {
      position: relative;
      margin-bottom: 2rem;
      padding: 1rem;
      background: #f1f1f1;

      &:before {
        position: relative;
        top: -0.6rem;
        float: left;
        display: inline-block;
        margin-right: 2rem;
        font-size: 2.4rem;
        font-family: $icon-font;
        @include bolded;
        color: #9f2042;
        content: "\e90e";
      }

      a {
        @include bolded;
        color: $body-color;

        .aed-icon {
          position: relative;
          top: 0.2rem;
          left: 0.5rem;
          font-size: 2.2rem !important;
        }
      }
    }

    .afuf-elt {
      margin-bottom: 1.5rem;

      &.odd {
        .inner {
          //padding-bottom: 1.5rem;
          //border-bottom: 0.1rem solid #dedede;
        }
      }

      .inner {
        padding: 0 7rem 1.5rem 0;
        border-bottom: 0.1rem solid #dedede;

        .ae-formation {
          border-right: 0.1rem solid #dedede;
        }

        .form-group {
          margin-bottom: 1.5rem;

          .form-control {
            min-height: 2rem;
            padding: 0.6rem 1.5rem;
          }
        }
      }
    }
  }
}
/*------------------------------------*\
    CUSTOM MEDIA QUERIES
\*------------------------------------*/
@media (max-width: 319px) {
}
@media (min-width: 32rem) and (max-width: 480px) {
}
@media (max-width: 480px) {
}
@media (max-width: 767px) {
  .agent-detail {
    .agent-detail-header {
      .inner {
        .adh-social {
          position: relative;
          margin-top: 0.5rem;
          padding-top: 1.5rem;
          border-left: 0 none;

          &:before {
            position: absolute;
            top: 0;
            right: 1.5rem;
            left: 1.5rem;
            width: auto;
            height: 0.1rem;
            background: #dedede;
            content: '';
          }

          nav {
            a {
              margin-bottom: 0 !important;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 480px) and (max-width: 979px) {
}
@media (max-width: 979px) {
}
@media (min-width: 979px) and (max-width: 1024px) {
}
@media (min-width: 979px) and (max-width: 1080px) {
}
@media (max-width: 1024px) {
}
@media (min-width: 1024px) and (max-width: 1200px) {
}
@media (max-width: 1200px) {
  .agent-detail {
    .agent-detail-header {
      .inner {
        .adh-social {
          nav {
            a {
              margin-bottom: 1.5rem;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 1200px) {
}
@media (min-width: 1200px) and (max-width: 1600px) {
}
@media (min-width: 1600px) and (max-width: 1800px) {
}
@media (min-width: 1600px) {
}
@media (max-width: 1600px) {
}
@media (max-width: 766px) {
}

