/*
 * @package    CNFPT
 * @project      AED
 * @author    <www.cnfpt.fr>
* @copyright  Copyright: (c) 2017 CNFPT
* @license    All right reserved.
*/

/*------------------------------------*\
    CONTRAST
\*------------------------------------*/
body {
  &.contrast-active {
    color: #ffffff;
    background: #000000 !important;

    //HEADER
    #masterhead {
      background: #000000 !important;
      border-bottom: 0.1rem solid #ffffff;

      .bg-light {
        color: #ffffff;
        background-color: #000000 !important;

        a {
          color: #ffffff;
        }

        .navbar-brand {
          background-color: #ffffff;
        }

        #headerMenu {

          .aed-account-actions {

            a {
              display: inline-block;
              color: #ffffff;
            }
          }

          .aed-accessibility {
            display: inline-block;
            height: 4rem;

            .aa-constrast {
              color: #ffffff;
              background: $primary-color;
            }

            .aa-font-resizer {
              color: #ffffff;
              background: $primary-color;

              #inc-font {

                &:before {
                  background: #ffffff;
                }

                &:after {
                  background: #ffffff;
                }
              }

              #dec-font {
                &:before {
                  background: #ffffff;
                }
              }
            }
          }
        }
      }
    }

    //FOOTER
    #colophon {
      color: #ffffff;
      background-color: #000000 !important;
      border-top: 0.1rem solid #ffffff;

      .footer-bottom {
        background: #000000;
        border-top: 0.1rem solid #ffffff;
      }
    }
  }
}