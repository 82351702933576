/*
 * @package    CNFPT
 * @project      AED
 * @author    <www.cnfpt.fr>
* @copyright  Copyright: (c) 2017 CNFPT
* @license    All right reserved.
*/

/* Bootstrap full (can import only part of it if necessary) */
//@import '../../../node_modules/bootstrap/scss/bootstrap';
