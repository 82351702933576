/*
 * @package    CNFPT
 * @project      AED
 * @author    <www.cnfpt.fr>
* @copyright  Copyright: (c) 2017 CNFPT
* @license    All right reserved.
*/
/*------------------------------------*\
    ALERT MESSAGES
\*------------------------------------*/
.alert {
    //position: relative;
    margin: 1.5rem 0 3rem 0;
    padding: 1rem 1.5rem;
    min-height: 4.5rem;
    line-height: 2rem;
    z-index: 2;
    @include border-radius(0.2rem);
    //@include box-shadow(0px 1px 2px 0px rgba(0, 0, 0, 0.2));

    .alert-close-btn {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        display: block;
        width: 3.4rem;
        height: 3.4rem;
        font-size: 1.6rem;
        line-height: 3rem;
        text-align: center;
        color: #ffffff;
        z-index: 1;
        cursor: pointer;
        @include border-radius(2px);
        @include transition-all(0.6s);

        &:hover, &:focus {
            @include transformize(rotate(360deg));
        }
    }

    .inner {
        position: relative;
        padding: 0 4rem 0 3.5rem;
        @include lighter;

        &:before {
            position: absolute;
            left: 0;
            display: inline-block;
            font-family: $icon-font;
            line-height: 8rem;
        }

        a {
            color: #ffffff;
            border-bottom: 1px dotted #ffffff;

            &:hover, &:focus {
            }
        }

        ul {
            padding-top: 0.3rem;
            padding-left: 2rem;
        }
    }

    &.alert-success {
        color: #ffffff;
        background: $nineth-color;
        border-color: $nineth-color;
        
        .inner:before {
            top: -3rem;
            content: "\e908";
            font-size: 2rem;
        }
    }

    &.alert-danger {
        color: #ffffff;
        background: $danger;
        border-color: $danger;

        .inner:before {
            top: -2.9rem;
            content: "\e932";
            font-size: 2.4rem;
        }
    }

    &.alert-warning {
        color: #ffffff;
        background: $warning;
        border-color: $warning;

        .inner:before {
            content: "\e92a";
            position: absolute;
            top: 0;
            left: 0;
            display: inline-block;
            width: 2.4rem;
            height: 2.4rem;
            font-family: $icon-font;
            font-size: 2.4rem;
            line-height: 2.4rem;
            color: #ffffff;
            @include border-radius(100%);
        }
    }

    &.alert-default {
        .inner:before {
            content: "\e630";
        }
        @include alert-variant($alert-default-bg, $alert-default-border, $alert-default-text);
    }

    &.not-assessed {
        margin-bottom: 3rem;
    }
}
