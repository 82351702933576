/*
 * @package    CNFPT
 * @project      AED
 * @author    <www.cnfpt.fr>
* @copyright  Copyright: (c) 2017 CNFPT
* @license    All right reserved.
*/

/*------------------------------------*\
    HEADER
\*------------------------------------*/

/** MASTERHEAD **/
#masterhead {
    position: relative;
    min-height: 5rem;
    background: #f8f9fa;
    z-index: 2;

    #navbar-toggler {
        display: inline-block;
        position: relative;
        float: left;
        width: 5rem;
        height: 5rem;
        @include transformize(rotate(0deg));
        @include transition(.3s ease-in-out);
        cursor: pointer;
        background: $primary-color;
        @include border-radius(0);
        border: none 0;
        outline: none;

        span {
            display: block;
            position: absolute;
            right: 1.3rem;
            left: 1.3rem;
            width: 2.4rem;
            height: 0.2rem;
            background: #ffffff;
            opacity: 1;
            @include transformize(rotate(0deg));
            @include transition(.3s ease-in-out);

            &:nth-child(1) {
                top: 1.6rem;
                @include transform-origin(left center);
            }

            &:nth-child(2) {
                top: 2.4rem;
                @include transform-origin(left center);
            }

            &:nth-child(3) {
                top: 3.2rem;
                @include transform-origin(left center);
            }
        }

        &.collapsed {
            span:nth-child(1) {
                top: 1.6rem;
                left: 1.6rem;
                @include transformize(rotate(45deg));
            }

            span:nth-child(2) {
                width: 0%;
                opacity: 0;
            }

            span:nth-child(3) {
                top: 3.2rem;
                left: 1.6rem;
                @include transformize(rotate(-45deg));
            }
        }

    }

    .navbar-brand {
        float: left;
    }

    .user-infos {
        float: left;
        display: block;
        height: 5.4rem;
        overflow: hidden;
    }

    .user-avatar {
      display: inline-block;
        width: 5.4rem;
        line-height: 4.4rem;
        background: #f4f4f4;
        border: 0.2rem solid #dedede;
        overflow: hidden;

        @include border-radius(100%);
    }

    a.user-infos {
        cursor: pointer;
    }

    .user-displayname {
        max-width: 50rem;
        margin-top: 0.5rem;
        font-weight: 700;
        text-transform: uppercase;
        margin-left: 1rem;
        color: #555454;
    }

    .header-separator {
        position: relative;
        display: block;
        float: left;
        width: 0.1rem;
        height: 5rem;
        margin: 0 2rem;
        background: #dedede;

        &:before {
            position: absolute;
            top: -0.5rem;
            width: 0.1rem;
            height: 6rem;
            content: '';
            background: #dedede;
            z-index: 1;
        }

        &.header-separator-sm {
            top: 0.6rem;
            height: 4rem;

            &:before {
                position: absolute;
                top: 0;
                width: 0.1rem;
                height: 4rem;
                content: '';
                background: #dedede;
                z-index: 1;
            }
        }
    }

    .navbar {
        display: inline-block;
        width: 100%;
        min-height: 8rem;
        padding-right: 2rem;
        padding-left: 2rem;
    }

    #headerMenu {
        display: inline-block;
        float: right;

        .aed-account-actions {
            display: inline-block;
            float: left;

            a {
                display: inline-block;
                float: left;
                margin-top: 0.5rem;
                @include bolded;
                @include uppercased;
                line-height: 4rem;
                color: $body-color;
                //border-right: 0.1rem solid #dedede;
                cursor: pointer;

                &.aaa-connexion {
                    .aed-icon {
                        margin: 0 0 0 2rem;
                    }
                }

                &.aaa-registration {
                    .aed-icon {
                        margin: 0 2rem 0 0;
                        font-size: 2.4rem;
                    }
                }

                &.aaa-logout {
                    .aed-icon {
                        margin: 0 2rem 0 0;
                        background: $third-color;
                    }
                }

                .aed-icon {
                    display: inline-block;
                    width: 4rem;
                    height: 4rem;
                    //margin: 0 2rem;
                    font-size: 2rem;
                    line-height: 4rem;
                    text-align: center;
                    color: #ffffff;
                    background: $primary-color;
                }
            }
        }

        .aed-accessibility {
            display: inline-block;
            height: 4rem;

            .aa-constrast {
                display: inline-block;
                float: left;
                width: 4rem;
                height: 4rem;
                margin: 0.5rem 2rem 0 0;
                font-size: 2rem;
                line-height: 4rem;
                text-align: center;
                color: $body-color;
                background: #edecec;
                @include transition-all(0.6s);
            }

            .aa-font-resizer {
                position: relative;
                top: 0.5rem;
                display: inline-block;
                width: 9rem;
                height: 4rem;
                line-height: 4rem;
                text-align: center;
                color: $body-color;
                background: #edecec;
                @include transition-all(0.6s);

                .afs-label {
                    position: relative;
                    top: -1.4rem;
                    font-size: 1.6rem;
                    @include bolded;
                }

                #inc-font {
                    position: relative;
                    display: inline-block;
                    width: 2rem;
                    height: 4rem;
                    margin-left: 0.2rem;
                    cursor: pointer;

                    &:before {
                        position: absolute;
                        top: 1.9rem;
                        left: 0.5rem;
                        display: inline-block;
                        content: "";
                        width: 1rem;
                        height: 0.2rem;
                        background: $body-color;
                        @include transition-all(0.6s);
                    }

                    &:after {
                        position: absolute;
                        top: 1.5rem;
                        left: 0.9rem;
                        display: inline-block;
                        content: "";
                        width: 0.2rem;
                        height: 1rem;
                        background: $body-color;
                        @include transition-all(0.6s);
                    }
                }

                #dec-font {
                    position: relative;
                    display: inline-block;
                    width: 2rem;
                    height: 4rem;
                    margin-left: 0;
                    cursor: pointer;

                    &:before {
                        position: absolute;
                        top: 1.9rem;
                        left: 0.5rem;
                        display: inline-block;
                        content: "";
                        width: 1rem;
                        height: 0.2rem;
                        background: $body-color;
                        @include transition-all(0.6s);
                    }
                }
            }
        }
    }

}