/*
 * @package    CNFPT
 * @project      AED
 * @author    <www.cnfpt.fr>
* @copyright  Copyright: (c) 2017 CNFPT
* @license    All right reserved.
*/

/*------------------------------------*\
    TABS
\*------------------------------------*/
.aed-tabs {
  .nav-pills {
    margin-bottom: 3rem;

    li {
      margin: 0 3rem 0 0;

      a {
        position: relative;
        display: inline-block;
        padding: 1.5rem 1.5rem;
        @include bolded;
        @include uppercased;
        color: $body-color;
        background: #ffffff;
        border: 0.2rem solid #dedede;
        @include transition-all(0.6s);

        &.active, &:hover, &:focus {
          color: #ffffff;
          background: $primary-color;
          border-color: $primary-color;

          &:after {
            position: absolute;
            bottom: -4.6rem;
            left: 50%;
            display: block;
            width: 2.6rem;
            height: 2.6rem;
            margin-left: -1.3rem;
            background: #ffffff;
            border: 0.2rem solid #dedede;
            border-right: 0 none;
            border-bottom: 0 none;
            @include border-radius(0.3rem);
            @include transformize(rotate(45deg));
            @include transition-all(0.6s);
            content: '';
            z-index: 1;
          }
        }

        &.disabled {
          color: #ffffff;
          background: grey;
          border-color: grey;
        }
      }
    }
  }

  .tab-pane {
    position: relative;
    padding: 1.5rem;
    border: 0.2rem solid #dedede;
  }
}

/*------------------------------------*\
    CUSTOM MEDIA QUERIES
\*------------------------------------*/
@media (max-width: 319px) {
}

@media (min-width: 32rem) and (max-width: 480px) {
}

@media (max-width: 480px) {
}

@media (max-width: 767px) {
}

@media (min-width: 480px) and (max-width: 979px) {
}

@media (max-width: 979px) {
}

@media (min-width: 979px) and (max-width: 1024px) {
}

@media (min-width: 979px) and (max-width: 1080px) {
}

@media (max-width: 1024px) {
}

@media (min-width: 1024px) and (max-width: 1200px) {
}

@media (max-width: 1200px) {
  .aed-tabs {
    .nav-pills {
      margin: 0;

      li {
        margin-bottom: 2rem;

        a {
          padding: 1rem 2rem;

          &.active, &:hover, &:focus {
            &:after {
              display: none;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1200px) {
}

@media (min-width: 1200px) and (max-width: 1600px) {
}

@media (min-width: 1600px) and (max-width: 1800px) {
}

@media (min-width: 1600px) {
}

@media (max-width: 1600px) {
}

@media (max-width: 766px) {
}

