/*
 * @package    CNFPT
 * @project      AED
 * @author    <www.cnfpt.fr>
* @copyright  Copyright: (c) 2017 CNFPT
* @license    All right reserved.
*/

/*------------------------------------*\
    SEARCH
\*------------------------------------*/

.search-job-count {
  .inner {
    background: #f4f4f4;
    padding-right: 1.5rem;
  }
  .sort-results {
    padding: 1rem 1.5rem 1rem 1.5rem;
    text-align: right;
    label {
      font-size: 1.6rem !important;
    }
    select {
      width: auto;
      display: inline-block;
      background: #fff;
      height: 2rem;
      min-height: 3rem;
      position: relative;
      top: -0.2rem;
    }
  }
  .sort-results, p {
    margin: 1rem 0 0 0;
    font-size: 2rem !important;
  }
  p {
    background: #f4f4f4;
    padding: 0 1.5rem 1rem 1.5rem;

    .aed-icon {
      position: relative;
      top: 0.6rem;
      font-size: 3rem;
    }

    .bolded {
      position: relative;
      top: 0.3rem;
      font-size: 3rem;
      margin-right: 0.5rem;
    }
  }
}
.aed-list-container {
  margin-top: 4em;

  .alc-collectivite-elt, .alc-agent-elt {
    a {
      color: $body-color;
    }
  }
}
.reset-search {
  text-align: right;
  color: #a7145a;
  a {
    border-bottom: 0.1rem dotted #a7145a;
    @include transition-all(0.6s);

    &:hover, &:focus {
      color: lighten(#a7145a, 10%);
      border-bottom-color: lighten(#a7145a, 10%);
    }
  }
}
.empty-block {
  margin-top: 3rem;
  @include empty-block(3rem, "\e92a", 0);
  .no-job {
    font-size: 2rem !important;
  }
}
/*------------------------------------*\
    CUSTOM MEDIA QUERIES
\*------------------------------------*/
@media (max-width: 320px) {
}
@media (max-width: 480px) {
}
@media (min-width: 480px) and (max-width: 979px) {
}
@media (max-width: 767px) {
}
@media (min-width: 767px) {
}
@media (max-width: 979px) {
}
@media (min-width: 979px) and (max-width: 1024px) {
}
@media (min-width: 979px) and (max-width: 1080px) {
}
@media (max-width: 1024px) {
}
@media (min-width: 1024px) and (max-width: 1200px) {
}
@media (max-width: 1200px) {
}
@media (min-width: 1200px) {
}
@media (min-width: 1200px) and (max-width: 1600px) {
}
@media (min-width: 1600px) and (max-width: 1800px) {
}
@media (min-width: 1600px) {
}
@media (max-width: 1600px) {
}
@media (max-width: 766px) {
}
