/*
 * @package    CNFPT
 * @author    <www.cnfpt.fr>
* @copyright  Copyright: (c) 2017 CNFPT
* @license    All right reserved.
*/
@use "sass:math";

@mixin generate-spacing($classSlug, $property)
{
        @for $i from 1 through 100 {
                .#{$classSlug}-#{$i}{
                        #{$property}:  $i + px;
                }
        }
}

$properties: (mar, margin), (pad, padding);

@mixin no-spacing($sideLabel, $side, $value) {
        @each $propLabel, $prop in $properties {

                .no-#{$propLabel}-#{$sideLabel}{
                        #{$prop}-#{$side}: $value;
                }
        }
}

body
{

        /* Colors */
        .primary-color{color: $primary-color;}
        .secondary-color{color: $secondary-color;}
        .third-color{color: $third-color;}
        .fourth-color{color: $fourth-color;}
        .eleven-color{color: $eleven-color;}
        .white-color{color: #ffffff;}
        .blue-color{color: $primary;}
        .success-color{color: $success;}
        .danger-color{color: $danger;}
        .warning-color{color: $warning;}

        //PADDING
        .no-pad{padding: 0;}
        .no-mar{margin: 0;}
        $noSpacings: (t, top, 0), (r, right, 0), (b, bottom, 0), (l, left, 0);

        @each $sideLabel, $side, $value in $noSpacings {

                @include no-spacing($sideLabel, $side, $value);
        }

        @include generate-spacing(pad, padding);
        @include generate-spacing(pad-t, padding-top);
        @include generate-spacing(pad-r, padding-right);
        @include generate-spacing(pad-b, padding-bottom);
        @include generate-spacing(pad-l, padding-left);

        //MARGIN
        @include generate-spacing(mar, margin);
        @include generate-spacing(mar-t, margin-top);
        @include generate-spacing(mar-r, margin-right);
        @include generate-spacing(mar-b, margin-bottom);
        @include generate-spacing(mar-l, margin-left);

}

/* Positions */
@mixin position($pos){position: $pos;}

/* Text font-size and line-height */
@mixin txt-it($coef){font-size: $coef; line-height: 100%;}
@mixin extra-lighter {font-weight: 200;}
@mixin lighter {font-weight: 300;}
@mixin normal {font-weight: 400;}
@mixin semi-bolded {font-weight: 600;}
@mixin bolded {font-weight: 700;}
@mixin ultra-bolded {font-weight: 800;}
@mixin italic {font-style: italic;}
@mixin lowercased {text-transform: lowercase;}
@mixin uppercased {text-transform: uppercase;}
@mixin normalized {text-transform: none;}
@mixin indented {text-indent: -9999px;}

body .lighter {@include lighter;}
body .normal {@include normal;}
body .semi-bolded {@include semi-bolded;}
body .bolded {@include bolded;}
body .ultra-bolded {@include ultra-bolded;}
body .italic {@include italic;}
body .lowercased {@include lowercased;}
body .uppercased {@include uppercased;}
body .normalized {@include normalized;}

//Border radius
@mixin border-radius($radii){
        -webkit-border-radius: $radii;
        -moz-border-radius: $radii;
        -o-border-radius: $radii;
        -ms-border-radius: $radii;
        border-radius: $radii;
}

.border-radius-5{@include border-radius(5px);}
.border-radius-10{@include border-radius(10px);}

//Animation transitions
@mixin transition-all($duration) {
        -webkit-transition: all $duration ease-in-out 0s;
        -moz-transition: all $duration ease-in-out 0s;
        -ms-transition: all $duration ease-in-out 0s;
        -o-transition: all $duration ease-in-out 0s;
        transition: all $duration ease-in-out 0s;
}

//Animations delay
@mixin delay-animation($duration){
        -webkit-animation-delay: $duration;
        -moz-animation-delay: $duration;
        -ms-animation-delay: $duration;
        -o-animation-delay: $duration;
        animation-delay: $duration;
}

/* Animation */
@mixin animation($animation) {
        -webkit-animation: $animation;
        -moz-animation: $animation;
        -ms-animation: $animation;
        -o-animation: $animation;
        animation: $animation;
}

/* Scale all */
@mixin transformize($val) {
        -webkit-transform: $val;
        -moz-transform: $val;
        -ms-transform: $val;
        -o-transform: $val;
        transform: $val;
}

@mixin transform-origin($val) {
        -webkit-transform-origin: $val;
        -moz-transform-origin: $val;
        -o-transform-origin: $val;
        transform-origin: $val;
}

/* Scale all */
@mixin scale-all($val) {
        -webkit-transform:scale($val);
        -moz-transform:scale($val);
        -ms-transform:scale($val);
        -o-transform:scale($val);
        transform:scale($val);
}

/* Scale X */
@mixin scale-x($val) {
        -webkit-transform:scaleX($val);
        -moz-transform:scaleX($val);
        -ms-transform:scaleX($val);
        -o-transform:scaleX($val);
        transform:scaleX($val);
}

/* Scale Y*/
@mixin scale-y($val) {
        -webkit-transform:scaleY($val);
        -moz-transform:scaleY($val);
        -ms-transform:scaleY($val);
        -o-transform:scaleY($val);
        transform:scaleY($val);
}

/* Box shadow */
@mixin box-shadow($shadows...){
        -webkit-box-shadow: $shadows;
        -moz-box-shadow: $shadows;
        -ms-box-shadow: $shadows;
        -o-box-shadow: $shadows;
        box-shadow: $shadows;
}
/* BACKGROUND */
body
{
        .primary-background{background: $primary-color;}
        .secondary-background{background: $secondary-color;}
        .third-background{background: $third-color;}
        .fourth-background{background: $fourth-color;}
        .white-background{background: #ffffff;}
        .blue-bkg{background: $info-alt;}
        .success-bkg{background: $success;}
        .danger-bkg{background: $danger;}
        .warning-bkg{background: $warning;}
}

.transparent-background {
        background-color: transparent;
        zoom: 1;
}

// The mixin
@mixin transparent($color, $alpha) {
        $rgba: rgba($color, $alpha);
        $ie-hex-str: ie-hex-str($rgba);
        background-color: transparent;
        background-color: $rgba;
        zoom: 1;
        filter:progid:DXImageTransform.Microsoft.gradient(startColorstr=#{$ie-hex-str},endColorstr=#{$ie-hex-str});
}

/*@include generate-spacing('pad', padding);*/

// Loop through opacities from 90 to 10 on an alpha scale
@mixin transparent-shades($name, $color) {
        @each $alpha in 90, 80, 70, 60, 50, 40, 30, 20, 10 {
                .#{$name}-#{$alpha} {
                        @include transparent($color, math.div($alpha,100));
                }
        }
}

// Generate semi-transparent backgrounds for the colors we want
@include transparent-shades('dark', #000000);
@include transparent-shades('light', #ffffff);

//Radial background
@mixin radial-background($colorfst, $colorsnd) {
        background: $colorfst; /* Old browsers */
        background: -moz-radial-gradient(center, ellipse cover,  $colorfst 0%, $colorsnd 100%); /* FF3.6+ */
        background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%,$colorfst), color-stop(100%,$colorsnd)); /* Chrome,Safari4+ */
        background: -webkit-radial-gradient(center, ellipse cover,  $colorfst 0%,$colorsnd 100%); /* Chrome10+,Safari5.1+ */
        background: -o-radial-gradient(center, ellipse cover,  $colorfst 0%,$colorsnd 100%); /* Opera 12+ */
        background: -ms-radial-gradient(center, ellipse cover,  $colorfst 0%,$colorsnd 100%); /* IE10+ */
        background: radial-gradient(ellipse at center,  $colorfst 0%,$colorsnd 100%); /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$colorfst, endColorstr=$colorsnd,GradientType=1 ); /* IE6-8 fallback on horizontal gradient */

}


@mixin radial-background-ie9-comp($colorfst, $colorsnd, $colorSVG){
        background: $colorfst; /* Old browsers */
        /* IE9 SVG, needs conditional override of 'filter' to 'none' */
        background: url('$colorSVG');
        background: -moz-radial-gradient(center, ellipse cover, $colorfst 0%, $colorsnd 100%); /* FF3.6+ */
        background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%,$colorfst), color-stop(100%,$colorsnd)); /* Chrome,Safari4+ */
        background: -webkit-radial-gradient(center, ellipse cover, $colorfst 0%,$colorsnd 100%); /* Chrome10+,Safari5.1+ */
        background: -o-radial-gradient(center, ellipse cover, $colorfst 0%,$colorsnd 100%); /* Opera 12+ */
        background: -ms-radial-gradient(center, ellipse cover, $colorfst 0%,$colorsnd 100%); /* IE10+ */
        background: radial-gradient(ellipse at center, $colorfst 0%,$colorsnd 100%); /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$colorfst, endColorstr=$colorsnd,GradientType=1 ); /* IE6-8 fallback on horizontal gradient */
}

// Transparent circles generation
@mixin transparent-circles($name, $border-width, $border-color) {
        @each $size in 550, 450, 350, 250, 150, 50 {
                .#{$name}-#{$size} {
                        width: #{$size}px;
                        height: #{$size}px;
                        text-align: center;
                        line-height: #{$size}px;
                        background: transparent;
                        border: $border-width solid $border-color;
                        @include border-radius(100%);
                }
        }
}

//Opaque circles generation
@mixin opaque-circles($name, $border-width, $border-color) {
        @each $size in 550, 450, 350, 250, 150, 50 {
                .#{$name}-#{$size} {
                        width: #{$size}px;
                        height: #{$size}px;
                        text-align: center;
                        line-height: #{$size}px;
                        background: $border-color;
                        border: $border-width solid $border-color;
                        @include border-radius(100%);
                }
        }
}

@mixin transition($transition...) {
        @if $enable-transitions {
                @if length($transition) == 0 {
                        transition: $transition-base;
                } @else {
                        transition: $transition;
                }
        }
}

@mixin transition-alt($prop, $time, $easing){
        -webkit-transition: $prop $time $easing;
        -moz-transition: $prop $time $easing;
        -ms-transition: $prop $time $easing;
        -o-transition: $prop $time $easing;
        transition: $prop $time $easing;
}
@mixin rounded($value){
        -webkit-border-radius: $value;
        -moz-border-radius: $value;
        border-radius: $value;
}
@mixin transform($prop){
        -webkit-transition: $prop;
        -moz-transition: $prop;
        -ms-transition: $prop;
        -o-transition: $prop;
        transition: $prop;
}

/* Links */
