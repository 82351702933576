/*
 * @package    CNFPT
 * @project      AED
 * @author    <www.cnfpt.fr>
* @copyright  Copyright: (c) 2017 CNFPT
* @license    All right reserved.
*/

/*------------------------------------*\
    BUTTONS
\*------------------------------------*/
//body {
.btn {
    min-width: 16rem;
    font-size: 1.3rem;
    @include semi-bolded;
    @include uppercased;
    cursor: pointer;
    @include border-radius(0);
    @include transition-all(0.6s);
}

.btn-secondary {
    @include button-variant($btn-secondary-bg, $btn-secondary-border);

    &:hover, &:focus {
        border-bottom-color: darken($btn-secondary-color, 20%);
    }
}

.btn-eleventh {
    @include button-variant($btn-eleventh-bg, $btn-eleventh-border);

    &:hover, &:focus {
        border-bottom-color: darken($btn-eleventh-color, 20%);
    }
}

.btn-gray {
    @include button-variant($btn-gray-bg, $btn-gray-border);

    //&:hover, &:focus
    //{
    //        border-bottom-color: darken($btn-eleventh-color, 20%);
    //}
}

.btn-third {
    @include button-variant($btn-third-bg, $btn-third-border);

    &:hover, &:focus {
        border-bottom-color: darken($btn-third-color, 20%);
    }
}

.btn-dialog-sm {
    min-width: 1rem;
    max-width: 14rem;
}

.arrow-btn {
    position: relative;

    @include arrow-btn(0.8rem, 5rem, 0.8rem, 5rem, 1.3rem, #ffffff, "\e92c", 4.8rem);

    &.btn-lg {
        @include arrow-btn(10px, 0.8rem, 4rem, 8rem, 2.4rem, #ffffff, "\e92c", 6.4rem);
    }

    &.btn-sm {
        @include arrow-btn(0.8rem, 2rem, 0.8rem, 3.5rem, 1.4rem, #ffffff, "\e92c", 4rem);
    }

    &.btn-xs {
        @include arrow-btn(0.8rem, 2rem, 0.8rem, 4rem, 1.2rem, #ffffff, "\e92c", 6.4rem);
    }
}

.btn-group-vertical {
    .btn-checkbox {
        margin-bottom: 0.1rem;
        padding: 0.4rem 2rem;
        font-size: 1.3rem;
        text-align: left;
        text-transform: none;
        border-bottom: 0.1rem solid darken($primary-color, 10%);

        &:first-child {
            @include border-radius(0.4rem 0.4rem 0 0);
        }

        &:last-child {
            @include border-radius(0 0 0.4rem 0.4rem);
        }

        input[type="radio"], input[type="checkbox"] {
            position: relative;
            top: 0.2rem;
        }
    }
}

.type-emploi > .active {
  color: white!important;
  background-color: $primary-color!important;
}

/*------------------------------------*\
    CUSTOM MEDIA QUERIES
\*------------------------------------*/
@media (max-width: 319px) {
    body {

        .arrow-btn {
            position: relative;

            @include arrow-btn(0.8rem, 2rem, 0.8rem, 3rem, 1.3rem, #ffffff, "\e902", 6.4rem);

            &.btn-lg {
                @include arrow-btn(0.8rem, 4rem, 0.8rem, 6rem, 2.4rem, #ffffff, "\e902", 6.4rem);
            }

            &.btn-sm {
                @include arrow-btn(0.8rem, 0, 0.8rem, 2rem, 1.4rem, #ffffff, "\e902", 6.4rem);
            }

            &.btn-xs {
                @include arrow-btn(0.8rem, 2rem, 0.8rem, 2rem, 1.2rem, #ffffff, "\e902", 6.4rem);
            }
        }
    }
}

@media (min-width: 32rem) and (max-width: 480px) {
    body {

        .arrow-btn {
            position: relative;

            @include arrow-btn(0.8rem, 2rem, 0.8rem, 5rem, 1.4rem, #ffffff, "\e902", 6.4rem);

            &.btn-lg {
                @include arrow-btn(0.8rem, 4rem, 0.8rem, 8rem, 2.4rem, #ffffff, "\e902", 6.4rem);
            }

            &.btn-sm {
                @include arrow-btn(0.8rem, 2rem, 0.8rem, 5rem, 1.4rem, #ffffff, "\e902", 6.4rem);
            }

            &.btn-xs {
                @include arrow-btn(0.8rem, 2rem, 0.8rem, 4rem, 1.2rem, #ffffff, "\e902", 6.4rem);
            }
        }
    }
}

@media (max-width: 480px) {
}

@media (min-width: 480px) and (max-width: 979px) {
}

@media (min-width: 767px) {
}

@media (max-width: 979px) {
}

@media (min-width: 979px) and (max-width: 1024px) {
}

@media (min-width: 979px) and (max-width: 1080px) {
}

@media (max-width: 1024px) {
}

@media (min-width: 1024px) and (max-width: 1200px) {
}

@media (max-width: 1200px) {
}

@media (min-width: 1200px) {
}

@media (min-width: 1200px) and (max-width: 1600px) {
}

@media (min-width: 1600px) and (max-width: 1800px) {
}

@media (min-width: 1600px) {
}

@media (max-width: 1600px) {
}

@media (max-width: 766px) {
}
