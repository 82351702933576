/*
 * @package    CNFPT
 * @project      AED
 * @author    <www.cnfpt.fr>
* @copyright  Copyright: (c) 2017 CNFPT
* @license    All right reserved.
*/

/*------------------------------------*\
    ANIMATIONS
\*------------------------------------*/
.css-spinner {
    width: 4rem;
    height: 4rem;
    border: 1rem solid #f3f3f3;
    border-top: 1rem solid #a7145a;
    @include border-radius(100%);
    animation: kc-circle-rotation 1.5s linear infinite;
}