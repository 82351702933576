/*
 * @package    CNFPT
* @project      AED
 * @author    <www.cnfpt.fr>
* @copyright  Copyright: (c) 2017 CNFPT
* @license    All right reserved.
*/
/*------------------------------------*\
    IMPORT
\*------------------------------------*/
@import "aed/variables";
@import '../../../node_modules/bootstrap/scss/bootstrap';
@import "mixins/mixins";
@import "mixins/animations";
@import "vendor/animate";

@import "aed/mixin/mixins";

@import "aed/content/agent";
@import "aed/content/banner";
@import "aed/content/collectivite";
@import "aed/content/assignation";
@import "aed/content/home";
@import "aed/content/jobs";
@import "aed/content/offers";
@import "aed/content/page";
@import "aed/content/news";
@import "aed/content/registration";
@import "aed/content/search";

@import "aed/core/alert";
@import "aed/core/animation";
@import "aed/core/buttons";
@import "aed/core/fonts";
@import "aed/core/form";
@import "aed/core/icon";
@import "aed/core/list";
@import "aed/core/misc";
@import "aed/core/modal";
@import "aed/core/panel";
@import "aed/core/tabs";

@import "aed/layout/contrast";
@import "aed/layout/footer";
@import "aed/layout/global";
@import "aed/layout/header";
@import "aed/layout/main";
@import "aed/layout/sidebar";
