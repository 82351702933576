/*
* @package    CNFPT
* @project      AED
* @author    <www.cnfpt.fr>
* @copyright  Copyright: (c) 2017 CNFPT
* @license    All right reserved.
*/

/*------------------------------------*\
PANEL
\*------------------------------------*/
body {
  &.contrast-active {
    .aed-accordion {
      .card {
        color: #ffffff;
        background: #000000;

        .card-header {

          &.active {
            a, button {
              color: #ffffff;
              background: $primary-color;
              border: 0.1rem solid $primary-color;

              &:before {
                color: #ffffff;
              }
            }
          }

          a, button {
            color: #ffffff;
            background: #000000;
            border: 0.1rem solid #ffffff;

            &:before {
              color: #ffffff;
              content: "\e90d";
            }
          }
        }

        .card-body {
          border: 0.1rem solid #ffffff;
          border-top: 0 none;
        }
      }
    }
  }
}

.aed-accordion {
  .card {
    margin-bottom: 3rem;
    border: 0;

    .show {
      .card-body {
        border: 0.1rem solid #dedede;
        border-top: 0;
      }
    }

    .card-header {
      position: relative;
      margin-bottom: 0;
      padding: 0;
      font-weight: 600 !important;
      @include uppercased;
      border: 0 none;

      button {
        display: block;
        width: 100%;
        text-align: left;
        padding: 1.5rem;
        font-size: 1.6rem;
        color: #555454;
        background: #ffffff;
        border: 0.1rem solid #dedede;

        &.open {
          color: #ffffff;
          background: $primary-color;
          border-color: $primary-color;

          &:before {
            color: #ffffff;
            @include transformize(rotate(180deg));
          }
        }

        &:focus {
          box-shadow: none;
          text-decoration: none;
        }

        &:before {
          position: absolute;
          top: 50%;
          right: 1.5rem;
          display: inline-block;
          margin-top: -1.2rem;
          font-family: $icon-font;
          font-size: 1.6rem;
          font-style: normal;
          color: #555454;
          content: "\e90d";
          @include transition-all(0.6s);
        }
      }
    }

    .card-body {

      .card-elt {
        margin-bottom: 3rem;
      }

      .bordered-title {
        margin-bottom: 3rem;
        font-size: 1.6rem;
        @include bolded;
      }

      .card-actions {
        margin-top: 3rem;
      }
    }
  }
  .accordion {
    > .card {
      overflow: visible;

    }
  }

}
