/*------------------------------------*\
    ANIMATIONS
\*------------------------------------*/

.kc-transition-all{@include transition-all(0.6s);}
.kc-transition-all-03{@include transition-all(0.3s);}
.kc-header-animation{ @include animation(kc-header-animation 2s 1);}
.kc-circle-rotation { @include animation(kc-circle-rotation 2s 1 infinite);}
.rotate {@include animation(rotate 2s 1 infinite);}

/*.kc-animated{-webkit-animation-fill-mode: both; -moz-animation-fill-mode: both; -ms-animation-fill-mode: both; -o-animation-fill-mode: both; animation-fill-mode: both; -webkit-animation-duration: 2s; -moz-animation-duration: 2s; -ms-animation-duration: 2s; -o-animation-duration: 2s; animation-duration: 2s;}*/

@mixin kc-infinite-animation($dur, $timing)
{
        -webkit-animation-duration: $dur; 
        animation-duration: $dur; 
        -webkit-animation-fill-mode: both; 
        animation-fill-mode: both; 
        -webkit-animation-timing-function: $timing; 
        animation-timing-function: $timing; 
        animation-iteration-count:infinite; 
        -webkit-animation-iteration-count:infinite; 
}

.animated.delay1 { @include delay-animation(0.1s); }
.animated.delay2 { @include delay-animation(0.2s); }
.animated.delay3 { @include delay-animation(0.3s); }
.animated.delay4 { @include delay-animation(0.4s); }
.animated.delay5 { @include delay-animation(0.5s); }
.animated.delay6 { @include delay-animation(0.6s); }
.animated.delay7 { @include delay-animation(0.7s); }
.animated.delay8 { @include delay-animation(0.8s); }
.animated.delay9 { @include delay-animation(0.9s); }
.animated.delay10 { @include delay-animation(1.0s); }
.animated.delay11 { @include delay-animation(1.1s); }
.animated.delay12 { @include delay-animation(1.2s); }
.animated.delay13 { @include delay-animation(1.3s); }
.animated.delay14 { @include delay-animation(1.4s); }
.animated.delay15 { @include delay-animation(1.5s); }
.animated.delay16 { @include delay-animation(1.6s); }
.animated.delay17 { @include delay-animation(1.7s); }
.animated.delay18 { @include delay-animation(1.8s); }
.animated.delay19 { @include delay-animation(1.9s); }
.animated.delay20 { @include delay-animation(2.0s); }
.animated.delay21 { @include delay-animation(2.1s); }
.animated.delay22 { @include delay-animation(2.2s); }
.animated.delay23 { @include delay-animation(2.3s); }
.animated.delay24 { @include delay-animation(2.4s); }
.animated.delay25 { @include delay-animation(2.5s); }
.animated.delay30 { @include delay-animation(3.0s); }
.animated.delay35 { @include delay-animation(3.5s); }
.animated.delay40 { @include delay-animation(4.0s); }
.animated.delay45 { @include delay-animation(4.5s); }
.animated.delay50 { @include delay-animation(5.0s); }
.animated.delay55 { @include delay-animation(5.5s); }
.animated.delay60 { @include delay-animation(6.0s); }
.animated.delay65 { @include delay-animation(6.5s); }
.animated.delay70 { @include delay-animation(7.0s); }
.animated.delay75 { @include delay-animation(7.5s); }
.animated.delay80 { @include delay-animation(8.0s); }
.animated.delay85 { @include delay-animation(8.5s); }
.animated.delay90 { @include delay-animation(9.0s); }
.animated.delay95 { @include delay-animation(9.5s); }
.animated.delay100 { @include delay-animation(10.0s); }


/* Header animation */
@-webkit-keyframes kc-header-animation {
    0% { top: -166px;}
    100% { top: 0;}
}

@-moz-keyframes kc-header-animation {
    0% { top: -166px;}
    100% { top: 0;}
}

@-ms-keyframes kc-header-animation {
    0% { top: -166px;}
    100% { top: 0;}
}

@-o-keyframes kc-header-animation {
    0% { top: -166px;}
    100% { top: 0;}
}

@keyframes kc-header-animation {
    0% { top: -166px;}
    100% { top: 0;}
}


/* Circle rotation */
@-webkit-keyframes kc-circle-rotation {
        from {
                -ms-transform: rotate(0deg);
                -moz-transform: rotate(0deg);
                -webkit-transform: rotate(0deg);
                -o-transform: rotate(0deg);
                transform: rotate(0deg);
        }
        to {
                -ms-transform: rotate(360deg);
                -moz-transform: rotate(360deg);
                -webkit-transform: rotate(360deg);
                -o-transform: rotate(360deg);
                transform: rotate(360deg);
        }
}

@-moz-keyframes kc-circle-rotation {
        from {
                -ms-transform: rotate(0deg);
                -moz-transform: rotate(0deg);
                -webkit-transform: rotate(0deg);
                -o-transform: rotate(0deg);
                transform: rotate(0deg);
        }
        to {
                -ms-transform: rotate(360deg);
                -moz-transform: rotate(360deg);
                -webkit-transform: rotate(360deg);
                -o-transform: rotate(360deg);
                transform: rotate(360deg);
        }
}

@-ms-keyframes kc-circle-rotation {
        from {
                -ms-transform: rotate(0deg);
                -moz-transform: rotate(0deg);
                -webkit-transform: rotate(0deg);
                -o-transform: rotate(0deg);
                transform: rotate(0deg);
        }
        to {
                -ms-transform: rotate(360deg);
                -moz-transform: rotate(360deg);
                -webkit-transform: rotate(360deg);
                -o-transform: rotate(360deg);
                transform: rotate(360deg);
        }
}

@-o-keyframes kc-circle-rotation {
        from {
                -ms-transform: rotate(0deg);
                -moz-transform: rotate(0deg);
                -webkit-transform: rotate(0deg);
                -o-transform: rotate(0deg);
                transform: rotate(0deg);
        }
        to {
                -ms-transform: rotate(360deg);
                -moz-transform: rotate(360deg);
                -webkit-transform: rotate(360deg);
                -o-transform: rotate(360deg);
                transform: rotate(360deg);
        }
}

@keyframes kc-circle-rotation {
        from {
                -ms-transform: rotate(0deg);
                -moz-transform: rotate(0deg);
                -webkit-transform: rotate(0deg);
                -o-transform: rotate(0deg);
                transform: rotate(0deg);
        }
        to {
                -ms-transform: rotate(360deg);
                -moz-transform: rotate(360deg);
                -webkit-transform: rotate(360deg);
                -o-transform: rotate(360deg);
                transform: rotate(360deg);
        }
}


/* Circle glowing effect */
@keyframes ring{
    0%{-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
    50%{opacity: 1.0;}
    100%{-webkit-transform: scale(1.2, 1.2);opacity: 0.0;}
}
 
@-webkit-keyframes ring{
    0%{-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
    50%{opacity: 1.0;}
    100%{-webkit-transform: scale(1.2, 1.2);opacity: 0.0;}
}
 
@-moz-keyframes ring{
    0%{-moz-transform: scale(0.1, 0.1);opacity: 0.0;}
    50%{opacity: 1.0;}
    100%{-moz-transform: scale(1.2, 1.2);opacity: 0.0;}
}
 
 
@-ms-keyframes ring{
    0%{-moz-transform: scale(0.1, 0.1);opacity: 0.0;}
    50%{opacity: 1.0;}
    100%{-moz-transform: scale(1.2, 1.2);opacity: 0.0;}
}
 
@-o-keyframes ring{
    0%{-o-transform: scale(0.1, 0.1);opacity: 0.0;}
    50%{opacity: 1.0;}
    100%{-o-transform: scale(1.2, 1.2);opacity: 0.0;}
}


@-webkit-keyframes rotate {
        0% {
                -webkit-transform: rotate(0deg) scale(1) skew(1deg) translate(0px); 
                -webkit-transform-origin: center;
        }

        100% {
                -webkit-transform: rotate(360deg) scale(1) skew(1deg) translate(0px);
                -webkit-transform-origin: center;
        }
}

@keyframes rotate {
          0% {
                transform: rotate(0deg) scale(1) skew(1deg) translate(0px); 
               transform-origin: center;
        }

        100% {
                transform: rotate(360deg) scale(1) skew(1deg) translate(0px);
                transform-origin: center;
        }
}

/*--- Bounce from left ---*/
/*.bounceFromLeft {animation-name: bounceFromLeft;}
@mixin bounce-from-left{
    0% {opacity: 0; transform: translateX(-3000px);}
    60% {opacity: 1; transform: translateX(30px);}
    80% {transform: translateX(-10px);}
    100% {transform: translateX(0);}
}
@-webkit-keyframes bounceFromLeft {@include bounce-from-left;}
@-moz-keyframes bounceFromLeft {@include bounce-from-left;}
@-ms-keyframes bounceFromLeft {@include bounce-from-left;}
@-o-keyframes bounceFromLeft {@include bounce-from-left;}
@-keyframes bounceFromLeft {@include bounce-from-left;}*/

/*--- Bounce from right ---*/
/*.bounceFromRight {animation-name: bounceFromRight;}
@mixin bounce-from-right{
    0% {opacity: 0; transform: translateX(3000px);}
    60% {opacity: 1; transform: translateX(-30px);}
    80% {transform: translateX(10px);}
    100% {transform: translateX(0);}    
}
@-webkit-keyframes bounceFromRight {@include bounce-from-right;}
@-moz-keyframes bounceFromRight {@include bounce-from-right;}
@-ms-keyframes bounceFromRight {@include bounce-from-right;}
@-o-keyframes bounceFromRight {@include bounce-from-right;}
@-keyframes bounceFromRight {@include bounce-from-right;}*/

/*--- Bounce from top ---*/
/*.bounceFromTop {animation-name: bounceFromTop;}
@mixin bounce-from-top {
    0% {opacity: 0; transform: translateY(-2000px);}
    60% {opacity: 1; transform: translateY(30px);}
    80% {transform: translateY(-10px);}
    100% {transform: translateY(0);}
}
@-webkit-keyframes bounceFromTop{@include bounce-from-top;}
@-moz-keyframes bounceFromTop{@include bounce-from-top;}
@-ms-keyframes bounceFromTop{@include bounce-from-top;}
@-o-keyframes bounceFromTop{@include bounce-from-top;}
@-keyframes bounceFromTop{@include bounce-from-top;}*/