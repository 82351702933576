/*
 * @package    CNFPT
 * @project      AED
 * @author    <www.cnfpt.fr>
* @copyright  Copyright: (c) 2017 CNFPT
* @license    All right reserved.
*/

/*------------------------------------*\
    HOME
\*------------------------------------*/

@mixin home-iconic-btn($icon_content, $span_top) {
    padding: 0 2rem;
    @include btn-iconic-wide(100%, 5rem, 1.5rem, 1.2rem, 3rem, #ffffff, $icon_content);

    span {
        position: relative;
        top: $span_top;
    }
}

body {
    &.contrast-active {
        #aed-home {

            //Widgets
            .aed-page-top {
                .apt-widgets {
                    .apt-widget {
                        &.aw-first {
                            .inner {
                                background: #000000;
                            }
                        }

                        &.aw-second {
                            .inner {
                                background: lighten(#000000, 10%);
                            }
                        }

                        &.aw-third {
                            padding-left: 0;

                            .inner {
                                background: #000000;
                            }
                        }

                        .inner {
                            color: #ffffff;

                            .apt-widget-tooltip {
                                @include circle-iconic(4rem, 4rem, 1.8rem, $third-color, #ffffff, "\e92a");
                            }
                        }
                    }
                }
            }

            //Map
            .aed-home-map {
                min-height: 30rem;
                margin-bottom: 4rem;
                padding: 1.5rem;
                background: #000000;
            }

            //News
            .aed-home-news {
                .ahn-news {
                    h3 {
                        color: #ffffff;
                        background: #000000;
                    }
                }
            }

            // Jobs
            .aed-home-jobs {
                .ahj-job {
                    a {
                        color: #ffffff;
                    }

                    h3 {
                        color: #ffffff;
                    }
                }
            }

            // Entries
            .aed-home-entries {
                .ahe-entry {
                    h3 {
                        color: #ffffff;
                        background: #000000;
                    }
                }
            }
        }
    }

    &.content-slided {
        #aed-home {
            //Widgets
            .aed-page-top {
                .apt-widgets {
                    .apt-widget {
                        .inner {
                            padding: 2rem 1rem;
                            .aed-icon {
                                //display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

#aed-home {
    position: relative;
    //background: transparent url('/assets/images/people.jpg') no-repeat top center;

    .alert-success {
        position: relative;
        top: 2rem;
        margin-top: 0;
    }

    //Widgets
    .aed-page-top {
        position: relative;
        min-height: 46rem;

        .apt-widgets {
            position: absolute;
            bottom: -7.5rem;
            width: 100%;

            .apt-widget {
                &.aw-first {
                    padding-right: 0;

                    .inner {
                        background: #8e6c7c;

                        .apt-widget-infos {
                            position: relative;
                            //top: -1.8rem;
                            margin-left: 1.6rem;
                        }
                    }
                }

                &.aw-second {
                    padding-right: 0;
                    padding-left: 0;

                    .inner {
                        background: $primary-color;

                        .apt-widget-infos {
                            margin-left: 2rem;
                        }
                    }
                }

                &.aw-third {
                    padding-left: 0;

                    .inner {
                        background: #8e6c7c;

                        .apt-widget-infos {
                            position: relative;
                            top: -1.8rem;
                            //margin-left: 1.6rem;
                        }
                    }
                }

                .inner {
                    position: relative;
                    height: 15rem;
                    padding: 2rem;
                    text-align: center;
                    color: #ffffff;

                    .apt-widget-tooltip {
                        position: absolute;
                        top: -2rem;
                        right: 2rem;
                        @include circle-iconic(4rem, 4rem, 1.8rem, #ffffff, $third-color, "\e92a");
                    }

                    .tooltip {
                        font-size: 1rem;
                        line-height: 1.6rem;
                        @include uppercased;
                    }

                    .aed-icon {
                        display: inline-block;
                        font-size: 11rem;
                        //font-weight: 600;
                        @include transition-all(0.6s);

                        &.aed-icon-user {
                            position: relative;
                            top: -1rem;
                            font-size: 13rem;
                        }
                    }

                    .apt-widget-infos {
                        display: inline-block;

                        app-loader-small {
                            .spinner-small {
                                margin: 0 auto;
                            }
                        }

                        .apt-widget-number {
                            display: block;
                            margin-bottom: 1.5rem;
                            font-size: 6rem;
                            @include ultra-bolded;
                            line-height: 4rem;
                        }

                        .apt-widget-label {
                            display: block;
                            text-align: center;
                            @include uppercased;

                            &.lighter {
                                margin-bottom: 0.5rem;
                                font-size: 1.8rem;
                                line-height: 1.8rem;
                            }

                            &.bolded {
                                font-size: 2.2rem;
                                line-height: 2.2rem;
                            }
                        }
                    }

                }
            }
        }
    }

    //Search form
    .aed-highlighted-form {
        margin-top: 11.5rem;
        margin-bottom: 4rem;

        .form-group {
            margin-bottom: 0;
        }

        .advanced-search {
            margin-top: 0.5rem;
            margin-left: 2rem;
            color: #a7145a;
            a {
                border-bottom: 0.1rem dotted #a7145a;
                @include transition-all(0.6s);

                &:hover, &:focus {
                    color: lighten(#a7145a, 10%);
                    border-bottom-color: lighten(#a7145a, 10%);
                }
            }
        }
    }

    //Map
    .aed-home-map {
        min-height: 30rem;
        margin-bottom: 4rem;
        padding: 1.5rem;
        background: #f4f4f4;

        .ahm-map {

            app-loader-small {
                .spinner-small {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin: -2rem -2rem;
                }
            }

            #aed-map {
                position: relative;
                left: -0.05rem;
                top: -0.0849976rem;
                padding-bottom: 8rem;
                overflow: visible;
            }
        }

        .ahm-infos {

            h3 {
                min-height: 9.8rem;
                margin-bottom: 2rem;
            }

            .u-are-manager {
                position: relative;
                min-height: 6rem;
                margin-bottom: 3rem;

                .iconic-btn {
                    @include home-iconic-btn("\e928", 0.8rem);
                }
            }

            .u-are-recruiter {
                position: relative;
                min-height: 6rem;
                margin-bottom: 3rem;

                .iconic-btn {
                    @include home-iconic-btn("\e912", 0.8rem);

                    //span {
                    //  margin-right: 1rem;
                    //}
                }
            }

            .ahm-selected {
                margin-bottom: 3rem;

                .as-number, .as-icon, .as-label {
                    display: inline-block;
                }

                .as-number {
                    position: relative;
                    top: -1.2rem;
                    margin-right: 2rem;
                    font-size: 6rem;
                    @include ultra-bolded;
                    line-height: 6rem;
                }

                .as-icon {
                    margin-right: 2rem;
                    font-size: 8rem;
                }

                .as-label {
                    position: relative;
                    top: -1.4rem;

                    span {
                        display: block;
                        @include uppercased;

                        &.lighter {
                            font-size: 1.8rem;
                            line-height: 1.8rem;
                        }

                        &.bolded {
                            font-size: 2.2rem;
                            line-height: 2.2rem;
                        }
                    }
                }
            }

            .ahm-actions {
                .btn {
                    min-width: 26rem;
                    cursor: pointer;
                }
            }
        }
    }

    //News
    .aed-home-news {
        margin-bottom: 4rem;
    }

    //Home blocs
    .aed-home-bloc {
        margin-bottom: 4rem;
    }

    //Jobs
    .aed-home-jobs {
    }

    //  Entries
    .aed-home-entries {
        margin-bottom: 4rem;

        .ahe-entry {
            position: relative;
            margin-bottom: 3rem;
            //max-height: 17.7rem;
            //overflow: hidden;

            .ahe-entry-img {
                position: relative;
                max-height: 17.7rem;
                overflow: hidden;
            }

            h3 {
                //position: absolute;
                //right: 0;
                //bottom: -5rem;
                //left: 0;
                width: 100%;
                margin: 0;
                padding: 1rem;
                font-size: 1.4rem;
                @include bolded;
                color: #ffffff;
                background: $primary-color;

                span {
                    display: block;

                    &.lighter {
                        font-size: 1.4rem;
                    }
                }
            }
        }
    }

    .aed-dev-message {
        .inner {
            padding: 1.5rem;
            background: #f6f6f6;
            border: 0.1rem solid #dedede;

            p {
                margin: 0;
                color: #555454;

                &:before {
                    position: relative;
                    top: -0.4rem;
                    float: left;
                    display: inline-block;
                    width: 3rem;
                    height: 3rem;
                    margin-right: 2rem;
                    font-size: 2rem;
                    font-family: $icon-font;
                    line-height: 3rem;
                    text-align: center;
                    color: #ffffff;
                    content: "\e92a";
                    background: #9f2042;
                    @include border-radius(100%);
                }
            }

            .btn {
                margin-top: 2rem;
            }
        }
    }
}

/*------------------------------------*\
    CUSTOM MEDIA QUERIES
\*------------------------------------*/
@media (max-width: 320px) {
}

@media (max-width: 480px) {
}

@media (min-width: 480px) and (max-width: 979px) {
}

@media (max-width: 767px) {
    #aed-home {
        //Widgets
        .aed-page-top {
            .apt-widgets {
                .apt-widget {
                    &.aw-first {
                        padding-right: 1.5rem;

                        .inner {

                            .apt-widget-infos {
                                margin-left: 1.6rem;
                            }
                        }
                    }

                    &.aw-second {
                        padding-right: 1.5rem;
                        padding-left: 1.5rem;

                        .inner {
                            .apt-widget-infos {
                                margin-left: 2rem;
                            }
                        }
                    }

                    &.aw-third {
                        padding-left: 1.5rem;

                        .inner {
                            .apt-widget-infos {
                                position: relative;
                                top: -1.8rem;
                                //margin-left: 1.6rem;
                            }
                        }
                    }

                    .inner {
                        position: relative;
                        height: 15rem;
                        padding: 2rem;
                        text-align: center;
                        color: #ffffff;

                        .apt-widget-tooltip {
                            position: absolute;
                            top: -2rem;
                            right: 2rem;
                        }

                        .tooltip {
                            font-size: 1rem;
                            line-height: 1.6rem;
                        }

                        .aed-icon {
                            display: none;
                        }

                        .apt-widget-infos {
                            display: inline-block;

                            .apt-widget-number {
                                display: block;
                                margin-bottom: 1.5rem;
                                font-size: 6rem;
                                line-height: 4rem;
                            }

                            .apt-widget-label {
                                display: block;
                                text-align: center;

                                &.lighter {
                                    margin-bottom: 0.5rem;
                                    font-size: 1.8rem;
                                    line-height: 1.8rem;
                                }

                                &.bolded {
                                    font-size: 2.2rem;
                                    line-height: 2.2rem;
                                }
                            }
                        }
                    }
                }
            }
        }

        //Search form
        .aed-highlighted-form {
            margin-top: 11.5rem;
            margin-bottom: 4rem;

            .form-group {
                margin-bottom: 1.5rem;
            }

            .advanced-search {
                margin-top: 0.5rem;
                margin-left: 2rem;
                color: #a7145a;

                a {
                    border-bottom: 0.1rem dotted #a7145a;
                    @include transition-all(0.6s);

                    &:hover, &:focus {
                        color: lighten(#a7145a, 10%);
                        border-bottom-color: lighten(#a7145a, 10%);
                    }
                }
            }
        }

        //Jobs
        .aed-home-jobs {
            margin-bottom: 4rem;

            .ahj-job {
                margin-bottom: 3rem;

                a {
                    display: block;
                    padding-bottom: 1.5rem;
                    color: #555454;
                    border-bottom: 0.1rem solid #dedede;
                }

                h3 {
                    margin: 0 0 1rem 0;
                }

                p {
                    margin-bottom: 2rem;
                }
            }
        }
    }
}

@media (min-width: 767px) {
}

@media (max-width: 979px) {
}

@media (min-width: 979px) and (max-width: 1024px) {
}

@media (min-width: 979px) and (max-width: 1080px) {
}

@media (max-width: 1024px) {
}

@media (min-width: 1024px) and (max-width: 1200px) {
}

@media (max-width: 1200px) {
    #aed-home {
        //Widgets
        .aed-page-top {
            min-height: 36rem;

            .apt-widgets {
                .apt-widget {
                    &.aw-first {
                        .inner {
                            .apt-widget-infos {
                                position: relative;
                                top: 0.6rem;
                            }
                        }
                    }

                    &.aw-second {
                        .inner {
                            .apt-widget-infos {
                                position: relative;
                                top: 0.6rem;
                            }
                        }
                    }

                    &.aw-third {
                        .inner {
                            .apt-widget-infos {
                                top: 0.6rem;
                            }
                        }
                    }

                    .inner {
                        .aed-icon {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 1200px) {
}

@media (min-width: 1200px) and (max-width: 1600px) {
}

@media (min-width: 1600px) and (max-width: 1800px) {
}

@media (min-width: 1600px) {
}

@media (max-width: 1600px) {
}

@media (max-width: 766px) {
}
