/*
 * @package    CNFPT
 * @project      AED
 * @author    <www.cnfpt.fr>
* @copyright  Copyright: (c) 2017 CNFPT
* @license    All right reserved.
*/

/*------------------------------------*\
    FOOTER
\*------------------------------------*/

@mixin fb-link($line_height) {
  float: left;

  a {
    display: block;
    width: 3.4rem;
    height: 3.4rem;
    font-size: 1.6rem;
    line-height: $line_height;
    text-align: center;
    color: #c2c3c4;
    border: 0.2rem solid #c2c3c4;
    @include border-radius(100%);
    @include transition-all(0.6s);

    &:hover, &:focus {
      color: lighten(#c2c3c4, 60%);
      border-color: lighten(#c2c3c4, 60%);
    }
  }
}

#colophon {
  position: relative;
  //bottom: 0;
  //width: 100%;
  min-height: 30rem;
  margin-top: 6rem;
  padding-bottom: 7rem;
  background-color: #242729;

  .scrolltotop {
    position: fixed;
    bottom: 6rem;
    right: 8rem;
    display: block;
    width: 6.6rem;
    height: 6.6rem;
    line-height: 7.2rem;
    background: $primary-color;
    @include border-radius(100%);
    z-index: 1040;
    outline: none;

    span {
      position: relative;
      display: inline-block;
      font-size: 1rem;
      @extend .text-uppercase;
      color: #ffffff;

      &:before {
        position: absolute;
        top: -1.5rem;
        left: -0.1rem;
        //margin-left: -37px;
        display: inline-block;
        font-family: $icon-font;
        font-size: 3rem;
        font-style: normal;
        color: #ffffff;
        content: "\e927";
        @include transition-all(0.6s);
      }

      &:hover, &:focus {
        &:before {
          transform: translateY(-0.5rem);
        }
      }
    }
  }

  .footer-top {
    width: 100%;
    padding: 3rem 1.5rem;
    color: #ffffff;

    @include generate-custom-list-style(inherit, inherit, 1rem, 2rem, 1.4rem, inherit, darken(#ffffff, 15%), -1rem, -1rem, $icon-font, 1rem, "\e903", #ffffff, "\e903", darken(#ffffff, 30%));
  }

  .footer-bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    min-height: 6rem;
    line-height: 6rem;
    color: #c2c3c4;
    background: #26292b;

    .fb-social-network {
      line-height: 6rem;
      color: #c2c3c4;

      .fsn-label {
        float: right;
      }

      ul {
        float: right;

        li {
          margin: 1.5rem 0 0 1.5rem;

          &.fb-twitter {
            @include fb-link(3rem);
          }
          &.fb-facebook {
            @include fb-link(3rem);
          }
          &.fb-youtube {
            @include fb-link(3rem);
          }
          &.fb-linkedin {
            @include fb-link(3rem);
          }
          &.fb-rss {
            @include fb-link(3rem);
          }
        }
      }
    }
  }
}

/*------------------------------------*\
    CUSTOM MEDIA QUERIES
\*------------------------------------*/
@media (max-width: 320px) {
}

@media (max-width: 480px) {
}

@media (min-width: 480px) and (max-width: 979px) {
}

@media (max-width: 767px) {
  #colophon {
    padding-bottom: 10rem;

    .footer-top {
      .ft-desc {
        margin-bottom: 3rem;
      }

      .ft-links {
        margin-bottom: 3rem;
      }

      .ft-logo {
        margin-bottom: 3rem;
      }
    }

    .footer-bottom {
      line-height: 3rem;
    }
  }
}

@media (min-width: 767px) {
}

@media (max-width: 979px) {
}

@media (min-width: 979px) and (max-width: 1024px) {
}

@media (min-width: 979px) and (max-width: 1080px) {
}

@media (max-width: 1024px) {
}

@media (min-width: 1024px) and (max-width: 1200px) {
}

@media (max-width: 1200px) {
}

@media (min-width: 1200px) {
}

@media (min-width: 1200px) and (max-width: 1600px) {
}

@media (min-width: 1600px) and (max-width: 1800px) {
}

@media (min-width: 1600px) {
}

@media (max-width: 1600px) {
}

@media (max-width: 766px) {
}
