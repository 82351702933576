/*
 * @package    CNFPT
 * @project      AED
 * @author    <www.cnfpt.fr>
* @copyright  Copyright: (c) 2017 CNFPT
* @license    All right reserved.
*/

/*------------------------------------*\
    REGISTRATION
\*------------------------------------*/
.registration-profiles-choice {
  .rpc-profile {
    cursor: pointer;

    .inner {
      position: relative;
      min-height: 16rem;
      padding: 2rem;
      text-align: center;
      color: $body-color;
      background: #fff;
      border: 0.2rem solid #dedede;
      @include transition-all(0.6s);

      &:hover, &:focus {
        color: #fff;
        background: $primary-color;
        border-color: $primary-color;
      }

      .aed-icon {
        display: inline-block;
        font-size: 11rem;
        //font-weight: 600;
        @include transition-all(0.6s);

        &.aed-icon-user {
          position: relative;
          top: -1rem;
          font-size: 13rem;
        }
      }

      .rp-widget-infos {
        position: relative;
        top: -4rem;
        display: inline-block;

        .rp-widget-number {
          display: block;
          margin-bottom: 1.5rem;
          font-size: 6rem;
          @include ultra-bolded;
          line-height: 4rem;
        }

        .rp-widget-label {
          display: block;
          text-align: center;
          @include uppercased;

          &.lighter {
            font-size: 1.4rem;
            line-height: 1.4rem;
          }

          &.bolded {
            margin-bottom: 1rem;
            font-size: 2.2rem;
            line-height: 2.2rem;
          }
        }
      }
    }
  }

  .rpc-profile-or, .rpc-profile-help {
    .inner {
      position: relative;
      min-height: 16rem;
      padding: 2rem;
      text-align: center;
      color: $body-color;
      background: #fff;
      @include transition-all(0.6s);

      .rp-widget-infos {
        position: relative;
        top: -4rem;
        display: inline-block;

        .rp-widget-number {
          display: block;
          margin-bottom: 1.5rem;
          font-size: 6rem;
          @include ultra-bolded;
          line-height: 4rem;
        }

        .rp-widget-label {
          display: block;
          @include uppercased;

          &.lighter {
            text-align: left;
            font-size: 1.4rem;
            line-height: 1.4rem;
            letter-spacing: 0.5px;
          }

          &.bolded {
            text-align: center;
            margin-bottom: 1rem;
            font-size: 2.2rem;
            line-height: 2.2rem;
          }
        }
      }
    }
  }

  .rpc-profile-or {
    padding-top: 8rem;
  }
}