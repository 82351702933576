/*
 * @package    CNFPT
 * @project      AED
 * @author    <www.cnfpt.fr>
* @copyright  Copyright: (c) 2017 CNFPT
* @license    All right reserved.
*/

/*------------------------------------*\
    FONTS
\*------------------------------------*/
$core-font-path: "/assets/fonts" !default;

@font-face {
  font-family: 'Open Sans';
  font-weight: 300;
  font-style: normal;
  src: url('#{$core-font-path}/OpenSans-Light.eot'),
  url('#{$core-font-path}/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
  url('#{$core-font-path}/OpenSans-Light.woff') format('woff'),
  url('#{$core-font-path}/OpenSans-Light.woff2') format('woff2'),
  url('#{$core-font-path}/OpenSans-Light.ttf') format('truetype'),
  url('#{$core-font-path}/OpenSans-Light.svg#open_sanslight') format('svg');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 400;
  font-style: normal;
  src: url('#{$core-font-path}/OpenSans-Regular.eot'),
  url('#{$core-font-path}/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
  url('#{$core-font-path}/OpenSans-Regular.woff') format('woff'),
  url('#{$core-font-path}/OpenSans-Regular.woff2') format('woff2'),
  url('#{$core-font-path}/OpenSans-Regular.ttf') format('truetype'),
  url('#{$core-font-path}/OpenSans-Regular.svg#open_sansregular') format('svg');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 400;
  font-style: italic;
  src: url('#{$core-font-path}/OpenSans-Italic.eot'),
  url('#{$core-font-path}/OpenSans-Italic.eot?#iefix') format('embedded-opentype'),
  url('#{$core-font-path}/OpenSans-Italic.woff') format('woff'),
  url('#{$core-font-path}/OpenSans-Italic.woff2') format('woff2'),
  url('#{$core-font-path}/OpenSans-Italic.ttf') format('truetype'),
  url('#{$core-font-path}/OpenSans-Italic.svg#open_sansitalic') format('svg');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 600;
  font-style: normal;
  src: url('#{$core-font-path}/OpenSans-Semibold.eot'),
  url('#{$core-font-path}/OpenSans-Semibold.eot?#iefix') format('embedded-opentype'),
  url('#{$core-font-path}/OpenSans-Semibold.woff') format('woff'),
  url('#{$core-font-path}/OpenSans-Semibold.woff2') format('woff2'),
  url('#{$core-font-path}/OpenSans-Semibold.ttf') format('truetype'),
  url('#{$core-font-path}/OpenSans-Semibold.svg#open_sanssemibold') format('svg');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 700;
  font-style: normal;
  src: url('#{$core-font-path}/OpenSans-Bold.eot'),
  url('#{$core-font-path}/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
  url('#{$core-font-path}/OpenSans-Bold.woff') format('woff'),
  url('#{$core-font-path}/OpenSans-Bold.woff2') format('woff2'),
  url('#{$core-font-path}/OpenSans-Bold.ttf') format('truetype'),
  url('#{$core-font-path}/OpenSans-Bold.svg#open_sansbold') format('svg');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 800;
  font-style: normal;
  src: url('#{$core-font-path}/OpenSans-ExtraBold.eot'),
  url('#{$core-font-path}/OpenSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('#{$core-font-path}/OpenSans-ExtraBold.woff') format('woff'),
  url('#{$core-font-path}/OpenSans-ExtraBold.woff2') format('woff2'),
  url('#{$core-font-path}/OpenSans-ExtraBold.ttf') format('truetype'),
  url('#{$core-font-path}/OpenSans-ExtraBold.svg#open_sansextrabold') format('svg');
}
