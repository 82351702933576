/*
 * @package    CNFPT
 * @project      AED
 * @author    <www.cnfpt.fr>
* @copyright  Copyright: (c) 2017 CNFPT
* @license    All right reserved.
*/

/*------------------------------------*\
    MISC
\*------------------------------------*/
body {
    &.contrast-active {
        // BREADCRUMBS
        .breadcrumb {
            color: #ffffff;
            background-color: #000000;
            border-bottom: 0.1rem solid #ffffff;

            ul {
                li {
                    &:after {
                        color: #ffffff;
                    }

                    &.bb-home {
                        a {
                            .aed-icon {
                                color: #ffffff;
                                border-bottom: 0.1rem dotted #ffffff;
                            }
                        }
                    }

                    a {
                        color: #ffffff;
                        border-bottom: 0.1rem dotted #ffffff;

                        &:hover, &:focus {
                            border-bottom: 1px dotted #ffffff;
                        }
                    }
                }
            }
        }

        //LISTS
        .links-list {
            li {
                a {
                    color: #ffffff;
                    border-bottom: 1px solid #ffffff;

                    &:before {
                        background: #ffffff;
                    }
                }
            }
        }

        .items-list {
            li {
                color: #ffffff;

                &:before {
                    background: #ffffff;
                }
            }
        }

        //NG DROPDOWN MULTISELECT
        ss-multiselect-dropdown {
            .dropdown {
                @include custom-dropdown-select-1_8;
            }
        }

        //AUTOCOMPLETE
        ngb-typeahead-window {
            &.dropdown-menu {

                .dropdown-item {
                    color: #000000;
                    background: #ffffff;
                    border-bottom: 0.1rem solid #ffffff;

                    &:hover, &:focus {
                        color: #000000;
                        background: darken(#ffffff, 10%);
                    }
                }
            }
        }
    }
}

//LINKS
a {
    text-decoration: none;
    outline: none;

    &:hover, &:focus {
        text-decoration: none;
        outline: none;
    }
}

//AED LABEL / VALUE
.aed-label-value {
    li {
        .alv-label {
            @include lighter;
        }
        .alv-value {
            @include bolded;
        }

        &.aed-label-value-iconic {
            .alv-label {
                margin-right: 1rem;
            }

            .alv-value {
                @include lighter;
            }
        }
    }
}

// BREADCRUMBS
.breadcrumb {
    min-height: 3rem;
    margin-bottom: 5rem;
    padding: 0;
    font-size: 1.3rem;
    background-color: #d8d9da;
    @include border-radius(0);

    .container {
        z-index: 2;
    }

    nav {
        margin-left: 1.5rem;
    }

    ul {
        float: left;
        margin: 0;
        padding: 0.5rem 0;

        > li + li:before {
            margin-left: 5px;
        }

        li {
            float: left;
            margin-right: 10px;

            &:after {
                float: right;
                display: inline-block;
                content: "\e91e";
                width: 0.4rem;
                height: 0.8rem;
                margin: 0.4rem 0 0 0.5rem;
                font-family: $icon-font;
                font-size: 0.8rem;
                color: #585757;
            }

            &.bb-home {
                a {
                    .aed-icon {
                        position: relative;
                        margin-right: 0.4rem;
                        font-size: 1.4rem;
                        color: $primary-color;
                    }
                }
            }

            a {
                @include bolded;
                color: #585757;
                @include transition-all(0.6s);

                &:hover, &:focus {
                    text-decoration: none;
                    border-bottom: 1px dotted #555454;
                }

                &.nolink {
                    @include normal;
                    border-bottom: 0 none;
                    cursor: default;

                    &:after {
                        @include bolded;
                    }
                }
            }

            &:last-child {
                &:after {
                    display: none;
                }
            }
        }
    }

    .font-resizer {
        float: right;
        width: 90px;
        min-height: 30px;
        line-height: 29px;
        text-align: center;
        color: #ffffff;
        background: #767676;

        .afs-label {
            font-size: 1.6rem;
            @include bolded;
        }

        #inc-font {
            position: relative;
            top: 4px;
            display: inline-block;
            width: 20px;
            height: 20px;
            margin-left: 2px;

            &:before {
                position: absolute;
                top: 9px;
                left: 5px;
                display: inline-block;
                content: "";
                width: 10px;
                height: 2px;
                background: #ffffff;
            }

            &:after {
                position: absolute;
                top: 5px;
                left: 9px;
                display: inline-block;
                content: "";
                width: 2px;
                height: 10px;
                background: #ffffff;
            }
        }

        #dec-font {
            position: relative;
            top: 4px;
            display: inline-block;
            width: 20px;
            height: 20px;
            margin-left: 0;

            &:before {
                position: absolute;
                top: 9px;
                left: 5px;
                display: inline-block;
                content: "";
                width: 10px;
                height: 2px;
                background: #ffffff;
            }
        }
    }
}

//TOOLTIPS
.tooltip {
    font-size: 1.2rem;
    line-height: 1.2rem;
}

// PAGINATION
.pagination {
    .page-item {
        .page-link {
            margin-left: 0.3rem;
            cursor: pointer;
            .aed-icon {
                font-size: 1rem;
            }
        }
    }
}

//LISTS
.links-list {
    li {
        margin-bottom: 0.6rem;

        a {
            position: relative;
            margin-left: 1.5rem;
            font-size: 1.4rem;
            @include semi-bolded;
            color: #6a6a6a;
            border-bottom: 1px solid #6a6a6a;

            &:before {
                position: absolute;
                top: 50%;
                left: -1.5rem;
                width: 0.8rem;
                height: 0.8rem;
                margin-top: -0.4rem;
                background: $primary-color;
                content: '';
            }
        }
    }
}

.items-list {
    li {

        position: relative;
        margin-bottom: 0.6rem;
        margin-left: 1.5rem;
        font-size: 1.4rem;
        @include normal;
        color: #6a6a6a;

        &:before {
            position: absolute;
            top: 50%;
            left: -1.5rem;
            width: 0.8rem;
            height: 0.8rem;
            margin-top: -0.4rem;
            background: $primary-color;
            content: '';
        }
    }
}

// LIST GROUP
.list-group {
    &.list-group-flush {
        .list-group-item {
            text-align: left;
            background: #ffffff;
            border: 0 none;

            &.odd {
                background: #f0f0f0;
            }

            .lgi-label {
                display: inline-block;
                width: 42%;
                vertical-align: top;
            }

            .lgi-value {
                display: inline-block;
                width: 56%;
                vertical-align: top;
                @include bolded;

                ul {
                    list-style: none;
                    padding: 0;
                }
            }
        }
    }
}

/* ==============================================================
    Accessibility
===============================================================*/

// custom size var declaration
$media-item-title: 1.8rem;
$icon-label: 1rem;
// END custom size var declaration

$accessibility-level: '1-4', '1-5', '1-6', '1-7', '1-8', '1-9', '2-0';
$accessibility-multiplicator: 1.4, 1.5, 1.6, 1.7, 1.8, 1.9, 2;

@for $i from 1 through 7 {
    $rem-mult: nth($accessibility-multiplicator, $i);
    .accessibility-text-rem-#{nth($accessibility-level, $i)} {
        label, p, a, button, input, tr, select, textarea {
            font-size: $font-size-base * $rem-mult;
        }
        h1 {
            font-size: $h1-font-size * $rem-mult;
        }
        h2 {
            font-size: $h2-font-size * $rem-mult;
        }
        h3 {
            font-size: $h3-font-size * $rem-mult;
        }
        h4 {
            font-size: $h4-font-size * $rem-mult;
        }
        h5 {
            font-size: $h5-font-size * $rem-mult;
        }
        h6 {
            font-size: $h6-font-size * $rem-mult;
        }
        .media-item-title {
            font-size: $media-item-title * $rem-mult;
            line-height: 2.67;
            letter-spacing: 0.2px;
        }
        .aed-icon {
            font-size: $icon-label * $rem-mult;
        }
    }
}

/* ==============================================================
    END Accessibility
===============================================================*/

//NG DROPDOWN MULTISELECT
ss-multiselect-dropdown {
    .dropdown {
        @include custom-dropdown-select-1_8;
    }
}

//AUTOCOMPLETE
ngb-typeahead-window {
    &.dropdown-menu {
        min-width: 95%;
        padding: 0 0 2rem 0;
        max-height: 20rem;
        overflow-y: scroll;
        @include border-radius(0rem);
        @include box-shadow(1px 4px 18px -9px rgba(0, 0, 0, 0.75));

        .dropdown-item {
            padding: 1rem 1.5rem;
            font-size: 1.3rem;
            @include uppercased;
            color: #767676;
            background: #e4e4e4;
            border-bottom: 0.1rem solid #ffffff;
            cursor: pointer;
            @include transition-all(0.6s);

            &:hover, &:focus {
                color: #ffffff;
                background: $primary-color;
            }
        }
    }
}

//CUSTOM SELECT

.ui-select-container {
    &.open {
        .dropdown-menu {
            display: block;
        }
    }

    &.open, &.show {
        .ui-select-toggle {
            font-size: 1.3rem;
            color: $body-color;
            background: #e4e4e4;
            border-color: #e4e4e4;

            &:after {
                color: $body-color;
                @include transformize(rotate(0deg));
            }
        }
    }

    .ui-select-toggle {
        position: relative;
        text-align: left;
        color: $body-color;
        background: #e4e4e4;
        border-color: #e4e4e4;

        &:after {
            position: absolute;
            top: 50%;
            right: 1.5rem;
            display: inline-block;
            width: 1.4rem;
            height: 1.4rem;
            margin-top: -0.7rem;
            margin-left: inherit;
            font-family: $icon-font;
            line-height: 1.4rem;
            content: "\e90d";
            color: $body-color;
            border: 0 none;
            @include transition-all(0.6s);
        }
    }

    .dropdown-toggle {
        display: none !important;
    }
    //@include custom-dropdown-select;
}

/* Style when highlighting a search. */
.ui-select-highlight {
    font-weight: bold;
}

.ui-select-offscreen {
    clip: rect(0 0 0 0) !important;
    width: 1px !important;
    height: 1px !important;
    border: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden !important;
    position: absolute !important;
    outline: 0 !important;
    left: 0px !important;
    top: 0px !important;
}

.ui-select-choices-row:hover {
    background-color: #f5f5f5;
}

/* Select2 theme */

/* Mark invalid Select2 */
.ng-dirty.ng-invalid > a.select2-choice {
    border-color: #D44950;
}

.select2-result-single {
    padding-left: 0;
}

.select2-locked > .select2-search-choice-close {
    display: none;
}

.select-locked > .ui-select-match-close {
    display: none;
}

body > .select2-container.open {
    z-index: 9999; /* The z-index Select2 applies to the select2-drop */
}

/* Handle up direction Select2 */
.ui-select-container[theme="select2"].direction-up .ui-select-match {
    border-radius: 4px; /* FIXME hardcoded value :-/ */
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.ui-select-container[theme="select2"].direction-up .ui-select-dropdown {
    border-radius: 4px; /* FIXME hardcoded value :-/ */
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    border-top-width: 1px; /* FIXME hardcoded value :-/ */
    border-top-style: solid;

    box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.25);

    margin-top: -4px; /* FIXME hardcoded value :-/ */
}

.ui-select-container[theme="select2"].direction-up .ui-select-dropdown .select2-search {
    margin-top: 4px; /* FIXME hardcoded value :-/ */
}

.ui-select-container[theme="select2"].direction-up.select2-dropdown-open .ui-select-match {
    border-bottom-color: #5897fb;
}

/* Selectize theme */

/* Helper class to show styles when focus */
.selectize-input.selectize-focus {
    border-color: #007FBB !important;
}

/* Fix input width for Selectize theme */
.selectize-control > .selectize-input > input {
    width: 100%;
}

/* Fix dropdown width for Selectize theme */
.selectize-control > .selectize-dropdown {
    width: 100%;
}

/* Mark invalid Selectize */
.ng-dirty.ng-invalid > div.selectize-input {
    border-color: #D44950;
}

/* Handle up direction Selectize */
.ui-select-container[theme="selectize"].direction-up .ui-select-dropdown {
    box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.25);

    margin-top: -2px; /* FIXME hardcoded value :-/ */
}

/* Bootstrap theme */

/* Helper class to show styles when focus */
.btn-default-focus {
    color: #333;
    background-color: #EBEBEB;
    border-color: #ADADAD;
    text-decoration: none;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.ui-select-bootstrap .ui-select-toggle {
    position: relative;
}

.ui-select-bootstrap .ui-select-toggle > .caret {
    position: absolute;
    height: 10px;
    top: 50%;
    right: 10px;
    margin-top: -2px;
}

/* Fix Bootstrap dropdown position when inside a input-group */
.input-group > .ui-select-bootstrap.dropdown {
    /* Instead of relative */
    position: static;
}

.input-group > .ui-select-bootstrap > input.ui-select-search.form-control {
    border-radius: 4px; /* FIXME hardcoded value :-/ */
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group > .ui-select-bootstrap > input.ui-select-search.form-control.direction-up {
    border-radius: 4px !important; /* FIXME hardcoded value :-/ */
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.ui-select-bootstrap > .ui-select-match > .btn {
    /* Instead of center because of .btn */
    text-align: left !important;
}

.ui-select-bootstrap > .ui-select-match > .caret {
    position: absolute;
    top: 45%;
    right: 15px;
}

.ui-disabled {
    background-color: #eceeef;
    border-radius: 4px;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 5;
    opacity: 0.6;
    top: 0;
    left: 0;
    cursor: not-allowed;
}

/* See Scrollable Menu with Bootstrap 3 http://stackoverflow.com/questions/19227496 */
.ui-select-bootstrap > .ui-select-choices {
    width: 100%;
    height: auto;
    max-height: 200px;
    overflow-x: hidden;
    margin-top: -1px;
}

body > .ui-select-bootstrap.open {
    z-index: 1000; /* Standard Bootstrap dropdown z-index */
}

.ui-select-multiple.ui-select-bootstrap {
    height: auto;
    padding: 3px 3px 0 3px;
}

.ui-select-multiple.ui-select-bootstrap input.ui-select-search {
    background-color: transparent !important; /* To prevent double background when disabled */
    border: none;
    outline: none;
    height: 1.666666em;
    margin-bottom: 3px;
}

.ui-select-multiple.ui-select-bootstrap .ui-select-match .close {
    font-size: 1.6em;
    line-height: 0.75;
}

.ui-select-multiple.ui-select-bootstrap .ui-select-match-item {
    outline: 0;
    margin: 0 3px 3px 0;
}

.ui-select-multiple .ui-select-match-item {
    position: relative;
}

.ui-select-multiple .ui-select-match-item.dropping-before:before {
    content: "";
    position: absolute;
    top: 0;
    right: 100%;
    height: 100%;
    margin-right: 2px;
    border-left: 1px solid #428bca;
}

.ui-select-multiple .ui-select-match-item.dropping-after:after {
    content: "";
    position: absolute;
    top: 0;
    left: 100%;
    height: 100%;
    margin-left: 2px;
    border-right: 1px solid #428bca;
}

.ui-select-bootstrap .ui-select-choices-row > a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
}

.ui-select-bootstrap .ui-select-choices-row > a:hover, .ui-select-bootstrap .ui-select-choices-row > a:focus {
    text-decoration: none;
    color: #262626;
    background-color: #f5f5f5;
}

.ui-select-bootstrap .ui-select-choices-row.active > a {
    color: #fff;
    text-decoration: none;
    outline: 0;
    background-color: #428bca;
}

.ui-select-bootstrap .ui-select-choices-row.disabled > a,
.ui-select-bootstrap .ui-select-choices-row.active.disabled > a {
    color: #777;
    cursor: not-allowed;
    background-color: #fff;
}

/* fix hide/show angular animation */
.ui-select-match.ng-hide-add,
.ui-select-search.ng-hide-add {
    display: none !important;
}

/* Mark invalid Bootstrap */
.ui-select-bootstrap.ng-dirty.ng-invalid > button.btn.ui-select-match {
    border-color: #D44950;
}

/* Handle up direction Bootstrap */
.ui-select-container[theme="bootstrap"].direction-up .ui-select-dropdown {
    box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.25);
}

.pre-wrap {
    white-space: pre-wrap;
}

.pre-line {
    white-space: pre-line;
}

.iconic-address {
    position: relative;
    padding-left: 3rem;
    .aed-icon {
      position: absolute;
      top: 0.4rem;
      left: 0;
      font-size: 1.6rem;
    }
}

.bubble-tag {
    display: inline-block;
    padding: 0 1rem;
    font-size: 1.2rem;
    margin-left: 0.5rem;
    background: #fff;
    border: .1rem solid #dedede;
    border-radius: 4rem;
    white-space: nowrap;
}

.top-separator {
    padding-top: 1rem;
    min-height: 4rem;
    margin-top: 1rem;
    border-top: .5px solid #dedede;
}

.dropdown-header {
    cursor: pointer;
}

ngb-datepicker {
  &.dropdown-menu {
    min-width: 21rem;
    //background: transparent;
    @include border-radius(0.5rem);

    .ngb-dp-header {
      padding: 0.8rem 0;
      background: $white;
      @include border-radius(0.5rem 0.5rem 0 0);

      .btn {
        min-width: inherit;
      }

      .ngb-dp-arrow-btn {
        &.btn-link {
          color: $primary-color;
        }
      }

      ngb-datepicker-navigation-select {
        > .custom-select {
          width: 7.2rem;
          min-height: 2.6rem;
          margin: 0 1rem;
          padding: 0 3rem 0 1rem;
          font-size: 1.2rem;
          background-color: $white;
          border-color: $primary-color;
        }
      }
    }

    .ngb-dp-months {
      background: $white;
      @include border-radius(0 0 0.5rem 0.5rem);

      .ngb-dp-month {
        width: 100%;

        .ngb-dp-day, .ngb-dp-week-number, .ngb-dp-weekday {
          width: 3rem;
          height: 3rem;
          line-height: 3rem;
        }

        .ngb-dp-day {
          &.ngb-dp-today {
            .btn-light {
              color: $white;
              background: $primary-color !important;
              outline: none;
            }
          }

          .btn-light {
            width: 3rem;
            height: 3rem;
            font-size: 1.2rem;
            line-height: 3rem;
            color: darken($primary-color, 5%);
            @include transition-all(0.6s);

            &.text-muted {
              color: darken($primary-color, 50%) !important;

              &.outside {
                opacity: 0.2;
              }
            }

            &:hover, &:focus {
              color: #ffffff;
              outline: none;
              background: darken($primary-color, 5%) !important;
            }
          }
        }

        .ngb-dp-week-number {
        }

        .ngb-dp-weekdays {
          background: darken($primary-color, 5%) !important;
        }

        .ngb-dp-weekday {
          font-size: 1.2rem;
          font-style: normal;
          color: #ffffff;
        }
      }
    }
  }
}
