/*
 * @package    CNFPT
 * @project      AED
 * @author    <www.cnfpt.fr>
* @copyright  Copyright: (c) 2017 CNFPT
* @license    All right reserved.
*/

/*------------------------------------*\
    MAIN
\*------------------------------------*/
body {
  &.not-front {
    #main {
      padding-top: 2.2rem;
      padding-bottom: 2rem;
    }
  }
}

#main {
  position: relative;
  min-height: 50rem;

  .dev-ribbon {
    position: absolute;
    top: -5rem;
    left: -7.5rem;
    width: 26rem;
    height: 23rem;
    background: transparent url('/assets/images/dev-ribbon.svg') no-repeat 0 -4rem;
    z-index: 1;
  }
}

.container {
  position: relative;
}