/*
 * @package    CNFPT
 * @project      AED
 * @author    <www.cnfpt.fr>
* @copyright  Copyright: (c) 2017 CNFPT
* @license    All right reserved.
*/

/*------------------------------------*\
    COLLECTIVITE
\*------------------------------------*/
body {
  &.contrast-active {
    .ct-detail {
      .ct-detail-header {
        .inner {
          background: #000000;

          .cdh-infos {
            .cdh-infos-title-link {
              .cdh-infos-link {
                color: #ffffff;

                .aed-icon {
                  color: #ffffff;
                }
              }
            }
          }
        }
      }

      .ct-job-agent {
        a {
          color: #ffffff;
        }
      }

      .ct-detail-vacancy {
        @include rec_top_right_badge(-1rem, 10%, 40rem, 3.6rem, 3.6rem, bold, #ffffff,
                $third-color, 0 0 0.5rem 0.5rem, 0 0.3rem 1rem -0.2rem rgba(0, 0, 0, 0.75), -1rem,
                0 0 1rem 1rem, transparent transparent darken($third-color, 10%) transparent);
      }

    }
  }
}

.ct-detail {
  .ct-detail-header {
    margin-bottom: 4rem;

    .inner {
      min-height: 16rem;
      @include inner-block(2rem 1rem);

      .cdh-infos {
        position: relative;

        .cdh-infos-title-link {
          margin-bottom: 3rem;

          .cdh-infos-link {
            position: absolute;
            top: 0;
            right: 1.5rem;
            font-size: 1.3rem;
            @include bolded;
            @include uppercased;
            color: $body-color;

            .aed-icon {
              position: relative;
              top: 0.3rem;
              font-size: 2rem;
              color: $primary-color;
            }
          }
        }
      }

      .adh-social {
        border-left: 0.1rem solid #dedede;
        @include as-social();
      }
    }
  }

  .ct-job-agent {
    a {
      color: $body-color;
    }
  }

  .aed-page-separator {
    margin-top: 0;
  }

  .ct-detail-vacancy {
    @include rec_top_right_badge(-1rem, 10%, 40rem, 3.6rem, 3.6rem, bold, #ffffff,
            $third-color, 0 0 0.5rem 0.5rem, 0 0.3rem 1rem -0.2rem rgba(0, 0, 0, 0.75), -1rem,
            0 0 1rem 1rem, transparent transparent darken($third-color, 10%) transparent);
  }

  .card-actions {
    margin: 1rem 0!important;

    .arrow-btn.btn-sm, .btn-group-sm > .arrow-btn.btn {
      padding: 0.6rem 2rem 0.6rem 4.5rem;
      font-size: 1.3rem;
    }
  }

  .current-affect {
    &.empty-block {
      margin-top: 1rem;

      .inner {
        min-height: 2rem;
        padding: 1.5rem!important;
      }
    }
  }
}

.collectivites-list {
  .table-header {
    color: #ffffff;
    background-color: $primary-color;
  }
}

/*------------------------------------*\
    CUSTOM MEDIA QUERIES
\*------------------------------------*/
@media (max-width: 320px) {
}

@media (max-width: 480px) {
}

@media (min-width: 480px) and (max-width: 979px) {
}

@media (max-width: 767px) {
  .ct-detail {
    .ct-detail-header {
      .inner {
        .cdh-thumb {
          margin-bottom: 2rem;
        }

        .cdh-infos-details {
          margin-top: 2rem;
        }
      }
    }
  }
}

@media (min-width: 767px) {
}

@media (max-width: 979px) {
}

@media (min-width: 979px) and (max-width: 1024px) {
}

@media (min-width: 979px) and (max-width: 1080px) {
}

@media (max-width: 1024px) {
}

@media (min-width: 1024px) and (max-width: 1200px) {
}

@media (max-width: 1200px) {
}

@media (min-width: 1200px) {
}

@media (min-width: 1200px) and (max-width: 1600px) {
}

@media (min-width: 1600px) and (max-width: 1800px) {
}

@media (min-width: 1600px) {
}

@media (max-width: 1600px) {
}

@media (max-width: 766px) {
}
