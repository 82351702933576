/*
 * @package    CNFPT
 * @project      AED
 * @author    <www.cnfpt.fr>
* @copyright  Copyright: (c) 2017 CNFPT
* @license    All right reserved.
*/

/*------------------------------------*\
    BANNER
\*------------------------------------*/
.aed-banner-slideshow {
  position: absolute;
  top: 0;
  right: -1.5rem;
  left: -1.5rem;
  width: 100%;
  min-height: 46rem;
  text-align: center;
  background: #f1f1f1;

  app-loader-small {
    .spinner-small {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -2rem -2rem;
    }
  }

  .as-caption, .carousel-item .as-caption {
    position: absolute;
    right: 35%;
    bottom: 12rem;
    left: 35%;
    padding: 2rem;
    color: #fff;
    text-align: center;
    background: rgba($third-color, 0.5);
    @include border-radius(0.5rem);
    //z-index: 10;
  }

  .carousel-indicators {
    bottom: 8rem !important;

    li {
      @include transition-all(0.6s);
      cursor: pointer;

      &:before, &:after {
        cursor: pointer;
      }
    }
  }

  .carousel-control-prev, .carousel-control-next {
    top: 50%;
    width: 5rem;
    height: 5rem;
    margin-top: -2.5rem;
    background: $third-color;
    @include transition-all(0.6s);
    cursor: pointer;
  }

  .carousel-control-next {
    right: 3rem;

    .carousel-control-next-icon {
      color: #ffffff;
      cursor: pointer;
    }
  }

  .carousel-control-prev {
    left: 3rem;

    .carousel-control-prev-icon {
      color: #ffffff;
      cursor: pointer;
    }
  }
}

/*------------------------------------*\
    CUSTOM MEDIA QUERIES
\*------------------------------------*/
@media (max-width: 319px) {
}

@media (min-width: 32rem) and (max-width: 480px) {
}

@media (max-width: 480px) {
}

@media (max-width: 767px) {
  .aed-banner-slideshow {
    //min-height: 20rem;

    .carousel-indicators, .carousel-control-next, .carousel-control-prev {
      display: none;
    }

    .as-caption, .carousel-item .as-caption {
      bottom: 40%;
      right: 20%;
      left: 20%;
    }

    .as-image {
      height: auto;
    }
  }
}

@media (min-width: 480px) and (max-width: 979px) {
}

@media (max-width: 979px) {
}

@media (min-width: 979px) and (max-width: 1024px) {
}

@media (min-width: 979px) and (max-width: 1080px) {
}

@media (max-width: 1024px) {
}

@media (min-width: 1024px) and (max-width: 1200px) {
}

@media (min-width: 767px) and (max-width: 1200px) {
  .aed-banner-slideshow {
    //min-height: 20rem;

    .carousel-indicators {
      display: none;
    }

    .as-caption, .carousel-item .as-caption {
      bottom: 40%;
      right: 20%;
      left: 20%;
    }
  }
}

@media (min-width: 1200px) {
}

@media (min-width: 1200px) and (max-width: 1600px) {
}

@media (min-width: 1600px) and (max-width: 1800px) {
}

@media (min-width: 1600px) {
}

@media (max-width: 1600px) {
}

@media (max-width: 766px) {
}

