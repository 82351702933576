/*
 * @package    CNFPT
 * @project      AED
 * @author    <www.cnfpt.fr>
* @copyright  Copyright: (c) 2017 CNFPT
* @license    All right reserved.
*/

/*------------------------------------*\
    LIST
\*------------------------------------*/
body {
  &.contrast-active {
    .aed-list-container {
      .alc-elt {
        .inner {
          color: #ffffff;

          &.alc-offer {
            h4 {
              color: #ffffff;
            }
          }

          .alc-elt-thumb {
            background: #000000;
            border: 0.3rem solid #ffffff;
          }

          .alc-elt-infos {
            color: #ffffff;

            address {
              color: #ffffff;
            }
          }

          h3 {
            &.title-primary {
              color: #ffffff;
            }

            &.title-secondary {
              color: #ffffff;
            }
          }
        }

        &.even {
          .inner {
            background: #000000;
          }
        }
        &.odd {
          .inner {
            background: #000000;
          }
        }
      }
    }
  }
}

.aed-list-container {
  .alc-elt {
    margin-bottom: 3rem;

    &.last {
      margin-bottom: 0 !important;
    }

    .inner {
      min-height: 16rem;
      padding: 1.5rem;

      &.alc-offer {

        h4 {
          font-size: 1.6rem;
          @include bolded;
          color: $eleven-color;
        }

        .alc-offer-metas {
          margin-bottom: 1.5rem;

          ul {
            display: block;

            li {
              float: left;
              margin-right: 2rem;
              @include bolded;

              .aed-icon {
                margin-right: 0.5rem;
              }
            }
          }
        }
      }

      .alc-elt-thumb {
        background: #ffffff;
        border: 0.3rem solid #dedede;
      }

      .alc-elt-infos {
        address {

        }
      }

      .alc-links {
        float: right;
        width: 100%;
        max-width: 22rem;

        &.wide {
          max-width: 100%;
        }

        a {
          display: block;
          float: right;
          width: 100%;
          margin-bottom: 1.5rem;
          font-size: 1.1rem;
        }
      }

      h3 {
        margin-bottom: 2rem;
        font-size: 1.6rem;
        @include bolded;

        &.title-primary {
          color: $primary-color;
        }

        &.title-secondary {
          color: $third-color;
        }
      }

      .ct-no-job-infos {
        margin: 2rem 0 0 0;

        .inner {
          min-height: 3rem !important;
          margin: 0 4rem;
        }
      }
    }

    &.even {
      .inner {
        background: #f6f6f6;
      }
    }

    &.odd {
      .inner {
        background: #ffffff;
      }
    }
  }

  div[ class ~= 'alc-elt' ]:last-of-type {
    margin-bottom: 0 !important;
  }
}

/*------------------------------------*\
    CUSTOM MEDIA QUERIES
\*------------------------------------*/
@media (max-width: 320px) {
}

@media (max-width: 480px) {
}

@media (min-width: 480px) and (max-width: 979px) {
}

@media (max-width: 767px) {
  .aed-list-container {
    .alc-collectivite-elt {
      .alc-elt-thumb {
        margin-bottom: 2rem;
      }
    }

    .alc-job-elt {
      margin-top: 2rem;
      margin-bottom: 2rem;

      h3 {
        padding-top: 2rem;
        border-top: 0.1rem solid #dedede;
      }

      ul {
        padding-top: 2rem;
        padding-bottom: 2rem;
        border-bottom: 0.1rem solid #dedede;
      }
    }

    .alc-agent-elt {
      .alc-elt-thumb {
        margin-bottom: 2rem;
      }
    }

    .alc-actions-elt {
      .alc-links {
        margin-top: 3rem;
        padding-top: 3rem;
        border-top: 0.1rem solid #dedede;
      }
    }
  }

}

@media (min-width: 767px) {
}

@media (max-width: 979px) {
}

@media (min-width: 979px) and (max-width: 1024px) {
}

@media (min-width: 979px) and (max-width: 1080px) {
}

@media (max-width: 1024px) {
}

@media (min-width: 1024px) and (max-width: 1200px) {
}

@media (max-width: 1200px) {
}

@media (min-width: 1200px) {
}

@media (min-width: 1200px) and (max-width: 1600px) {
}

@media (min-width: 1600px) and (max-width: 1800px) {
}

@media (min-width: 1600px) {
}

@media (max-width: 1600px) {
}

@media (max-width: 766px) {
}
