/*
 * @package    CNFPT
 * @project      AED
 * @author    <www.cnfpt.fr>
* @copyright  Copyright: (c) 2017 CNFPT
* @license    All right reserved.
*/

/*------------------------------------*\
    GLOBAL
\*------------------------------------*/
html {
  position: relative;
  min-height: 100%;
  font-size: 62.5%;
  overflow-x: hidden;

  body {
    &.admin-menu {
      margin: 0;
    }
  }
}

body {
  //margin-bottom: 6rem;
  font-size: 14px;
  font-size: 1.4rem;
}

#slidable-wrapper {
  position: relative;
  @include transition-all(0.6s);

  &.slided {
    @include transformize(translateX(38rem))
  }
}

/* Avoid silent refresh iframe in layout */
#angular-oauth-oidc-silent-refresh-iframe {
  position: absolute;
  bottom: 0;
  height: 0;
}

.clickable {
  cursor: pointer;
}
