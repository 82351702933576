/*
 * @package    CNFPT
 * @project      AED
 * @author    <www.cnfpt.fr>
* @copyright  Copyright: (c) 2017 CNFPT
* @license    All right reserved.
*/

/*------------------------------------*\
    FORM
\*------------------------------------*/
body {
    &.contrast-active {

        form {
            &.aed-highlighted-form {
                background: #000000;
                border: 0.1rem solid #ffffff;

                .form-control {
                    color: #000000;
                    background: #ffffff;
                }
            }
        }

        .field-info {
            background: lighten(#000000, 10%);
        }

        .form-group-separator {
            background: #ffffff;
        }

        .form-control {
            color: #000000;
            background: #ffffff;
            border-color: #ffffff;
        }
    }
}

form {
    &.aed-highlighted-form {
        padding: 1.5rem;
        background: #f4f4f4;
        border: 0.1rem solid #dedede;

        .form-control {
            color: $body-color;
            background: #ffffff;
        }
    }

    &.aed-entity-form {
        label {
            @include bolded;

            &.form-check-label.label-alone {
                @include bolded;
            }

            &.form-check-label {
                @include normal;
            }
        }
    }
}

.required-field {
    font-size: 1.1rem;
}

.field-info {
    min-height: 3rem;
    margin: 2rem 0 0 0;
    padding: 1rem 1.5rem;
    font-size: 1.2rem;
    line-height: 1.3rem;
    background: #f4f4f4;

    &:before {
        position: relative;
        top: -0.4rem;
        float: left;
        display: inline-block;
        width: 3rem;
        height: 3rem;
        margin-right: 2rem;
        font-size: 2rem;
        font-family: $icon-font;
        line-height: 3rem;
        text-align: center;
        color: #ffffff;
        content: "\e92a";
        background: #9f2042;
        @include border-radius(100%);
    }
}

.form-group {
    margin-bottom: 3rem;
}

.form-group-separator {
    height: 0.1rem;
    margin: 0 0 3rem 0;
    background: #dedede;
}

.form-control {
    min-height: 4rem;
    font-size: 1.4rem;
}

label {
    &.form-check-label {
        padding-left: 3rem;
    }

    &.form-check-label.label-alone {
        display: block;
        padding-left: 0;
    }
}

input {
    &.form-check-input {
        position: relative;
        margin-left: -3rem;
    }

    &.ng-invalid.ng-touched {
        border-color: $danger;
    }
}

textarea {
    &.form-control {
        min-height: 6rem;
    }

    &.ng-invalid.ng-touched {
        border-color: $danger;
    }
}

select {
    &.ng-invalid.ng-touched {
        border-color: $danger;
    }
}

//Error
.form-error {
    position: relative;
    min-height: 2rem;
    margin: 0.8rem 0 0 0;
    padding: 0.5rem 1.5rem;

    &:before {
        position: absolute;
        top: -0.5rem;
        left: 1.5rem;
        display: block;
        width: 1rem;
        height: 1rem;
        background: $danger;
        content: "";
        @include transformize(rotate(45deg));
    }

    small {
        display: block;
    }
}

.dropdown {
    &.custom-dropdown-select {
        @include custom-dropdown-select;
    }
}

//.dropdown-item {
//  white-space: normal;
//}
//
//.input-group-sm > .input-group-addon {
//  padding: 0.5rem 0;
//
//  input.form-control {
//    font-size: 1.3rem !important;
//  }
//}
//
//input.form-control {
//  font-size: 1.3rem !important;
//}

//CUSTOM
.profile-update-form {
    app-aed-typeahead {
        > .form-group {
            margin-bottom: 0;
        }
    }

    button[type="submit"] {
        padding-left: 5rem;
        padding-right: 3rem;
        @include bolded;
    }
}

.aed-form-edition {
    .fg-action {
        position: absolute;
        top: 0;
        right: 1.5rem;
        display: block;
        width: 4rem;
        height: 4rem;
        line-height: 4rem;
        @include bolded;
        text-align: center;
        color: $primary-color;
        border: 0.1rem solid #dedede;
        z-index: 1;
        @include transition-all(0.5s);

        &.fga-edit {
            font-size: 1.6rem;

            &:hover, &:focus {
                @include transformize(rotate(10deg) translateY(-0.5rem));
            }
        }

        &.fga-remove {
            top: 5rem;
        }

        &:hover, &:focus {
            @include transformize(rotate(360deg));
        }
    }

    .aed-list-container {
        margin-top: 0;
    }
}

angular2-multiselect {
  .selected-list {
    .c-list {
      .c-token {
        background-color: $primary;
        font-size: 1rem;
      }
    }
  }
  &.single-select {
    .selected-list {
      .c-list {
        .c-token {
          background-color: transparent;
          font-size: 1rem;
          padding: 0;
          margin: 0;
          .c-label {
            color: #333;
          }

        }
      }
    }
  }
  .c-btn {
    font-size: 1rem;
  }

  .pure-checkbox {
    input[type="checkbox"] + label{
      &::before {
        color: $primary;
        border-color: $primary;
      }
    }
  }

  .single-select-mode {
    .pure-checkbox {
      input[type="checkbox"] + label{
        &:before {
          border-color: $primary;
        }
        &:after {
          border-color: $primary;
        }
      }
    }
  }
}

#slidable-wrapper {
  &.slided {
    .dropdown-list {
      position: absolute !important;
      top: 100% !important;
      left: 0 !important;
    }
  }
}
