/*
 * @package    CNFPT
 * @project      AED
 * @author    <www.cnfpt.fr>
* @copyright  Copyright: (c) 2017 CNFPT
* @license    All right reserved.
*/

/*------------------------------------*\
    MODAL
\*------------------------------------*/
.modal {
    padding-top: 3rem;

    &.custom-modal-lg {
        .modal-dialog {
            @extend .modal-lg;
        }
    }

    .modal-content {
        border: none;
        @include box-shadow(0px 3px 20px -4px rgba(0, 0, 0, 0.75));

        .delete-dialog-form {
            .modal-header {
                padding: 1.2rem 1.2rem 1.4rem 1.2rem;

                h2 {
                    font-size: 1.4rem;
                   @include bolded;
                }

                button {
                    position: absolute;
                    top: 0.6rem;
                    right: 0;
                    margin: 0;
                }
            }
        }

        .modal-header {
            position: relative;

            h2 {
                margin: 0;
            }

            .close {
                position: relative;
                top: 1rem;
                @include bolded;
                color: $primary-color;
                cursor: pointer;
                @include transition-all(0.6s);
                opacity: 1!important;

                &:hover, &:focus {
                    transform: rotate(360deg);
                }
            }
        }

        .modal-body {
            margin-top: 1rem;

            .ajax-loader {
                padding-top: 0.5rem;
                margin-bottom: 1.5rem;
            }

            img {
                @extend .img-fluid;
            }

            .inner {

            }
        }

        //.modal-footer {
        //    padding: 15px;
        //    border-top: 1px solid #dedede;
        //}
    }
}