/*
 * @package    CNFPT
 * @project      AED
 * @author    <www.cnfpt.fr>
* @copyright  Copyright: (c) 2017 CNFPT
* @license    All right reserved.
*/

/*------------------------------------*\
    PAGE
\*------------------------------------*/
body {
    &.contrast-active {

        .page-title {
            h1 {
                color: #ffffff;

                &:after {
                    background: #ffffff;
                }
            }
        }

        .bordered-title {
            color: #ffffff;

            &:after {
                background: #ffffff;
            }

            &.bt-secondary {
                color: #ffffff;

                &:after {
                    background: #ffffff;
                }
            }
        }

        .aed-page-separator {
            .inner {
                background: #ffffff;
            }
        }
    }
}

.page-title {
    position: relative;
    margin: 0 0 5rem 0;

    h1 {
        margin: 0;
        color: $primary-color;

        &:after {
            position: absolute;
            bottom: -1.5rem;
            left: 0.4rem;
            content: "";
            width: 9rem;
            height: 0.2rem;
            background: $primary-color;
            z-index: 1;
        }
    }
}

.bordered-title {
    position: relative;
    margin: 0 0 5rem 0;
    @include uppercased;
    color: $primary-color;

    &:after {
        position: absolute;
        bottom: -1.5rem;
        left: 0.4rem;
        content: "";
        width: 9rem;
        height: 0.2rem;
        background: $primary-color;
        z-index: 1;
    }

    &.bt-secondary {
        color: #767676;

        &:after {
            background: #767676;
        }
    }

    &.bt-white {
        color: #ffffff;

        &:after {
            background: #ffffff;
        }
    }
}

.card-header > a {
    position: relative;
    margin: 0 0 5rem 0;
    @include uppercased;
    color: $primary-color;

    &.bt-secondary {
        color: #767676;

        &:after {
            background: #767676;
        }
    }

    &.bt-white {
        color: #ffffff;

        &:after {
            background: #ffffff;
        }
    }
}

.aed-page-separator {
    margin: 3rem 0;

    .inner {
        height: 0.1rem;
        background: #dedede;
    }
}

//Page detail
.page-detail {
    h2 {
        margin-top: 3rem;
        @extend .bordered-title;
    }

    h3 {
        margin-top: 3rem;
        @extend .bordered-title;
        @extend .bt-secondary;
    }

    ul {
        @extend .list-unstyled;
        @extend .items-list;
    }
}

// Page metas
.page-metas {
    min-height: 4rem;
    margin: 0 0 3rem 0;
    padding: 1rem 1.5rem 0 1.5rem;
    background: #f6f6f6;

    a {
        $icons: (aed-icon-back, -0.6rem, 1rem, 4rem), (aed-icon-email, -0.6rem, 1rem, 4rem);
        @include inline-iconic-link(2.4rem, #555454, 4.6rem, #555454, $icons);
    }
}
