/*
 * @package    CNFPT
 * @project      AED
 * @author    <www.cnfpt.fr>
* @copyright  Copyright: (c) 2017 CNFPT
* @license    All right reserved.
*/

/*------------------------------------*\
    OFFERS
\*------------------------------------*/

body {
  &.contrast-active {
    #aed-home {
      // Jobs
      .aed-jobs {
        .aj-job {
          a {
            color: #fff;
          }

          h3 {
            color: #fff;
          }
        }
      }
    }
  }
}
//Jobs
.aed-jobs {
  .aj-job {
    margin-bottom: 3rem;

    a {
      color: #555454;
    }

    h3 {
      min-height: 3.6rem;
      margin: 0 0 2rem 0;
      font-size: 1.6rem;
      @include bolded;
      color: #8e6c7c;
    }

    .aj-job-metas {
      display: block;
      margin-bottom: 2rem;

      li {
        display: inline-block;
        margin-right: 2rem;

        .aed-icon {
          margin-right: 1rem;

          &.aed-icon-clock {
            font-size: 1.6rem;
          }

          &.aed-icon-hash {
            font-size: 1.6rem;
          }
        }
      }
    }

    p {
      margin-bottom: 3rem;
    }

    .aj-job-location {
      .ajl-delegation {
        display: block;
        //margin-bottom: 1rem;
        @include bolded;
        @include uppercased;
      }

      .ajl-address {
        display: block;
        margin-bottom: 1rem;

        &:before {
          position: relative;
          top: 0.2rem;
          font-family: $icon-font;
          font-size: 2rem;
          content: "\e91c";
        }
      }
    }
  }
}
.offer-detail {
  .offer-detail-header {
    margin-bottom: 4rem;

    .inner {
      min-height: 16rem;
      @include inner-block(2rem 1rem);

      .odh-social {
        border-left: 0.1rem solid #dedede;
        @include as-social();
      }
    }
  }

  .od-desc-para {
    margin-bottom: 3rem;

    h3 {
      margin-bottom: 2rem;
    }

    .odp-para {
      white-space: pre-line;
    }
  }

  .od-card {
    min-height: 30rem;

    &.od-map {
      .card-body {
        padding: 0;
      }
    }

    h2 {
      margin: 0 0 4rem 0;
      font-size: 2.2rem;
    }
  }
}

agm-map {
  height: 36rem;
}