/*
 * @package    CNFPT
 * @project      AED
 * @author    <www.cnfpt.fr>
* @copyright  Copyright: (c) 2017 CNFPT
* @license    All right reserved.
*/

/*------------------------------------*\
    NEWS
\*------------------------------------*/

body {
  &.contrast-active {
    #aed-home {
      //News
      .aed-news {
        .ahn-news {
          h3 {
            color: #fff;
            background: #000;
          }
        }
      }
    }
  }
}
.aed-news {
  margin-bottom: 4rem;

  .ahn-news {
    margin-bottom: 3rem;

    h3 {
      margin: 0;
      padding: 1rem;
      font-size: 1.8rem;
      @include bolded;
      color: #767676;
      background: #e9e9e9;
    }

    h2 {
      margin: 0;
      padding: 0;
      font-size: 1.8rem;
      @include bolded;
      color: #767676;
    }

    .news-listing-date {
      display: inline-block;
      position: relative;
      padding-right: 1.5rem;
      font-weight: 400;
      margin-top: 1rem;
      margin-bottom: 1rem;
      font-size: 1.4rem;
    }

    .news-listing-date:after {
      position: absolute;
      top: 50%;
      right: 0;
      width: .1rem;
      height: 2.6rem;
      margin-top: -1.3rem;
      background: #dedede;
      content: '';
    }
  }

}
.news-date {
  text-align: center;
  @include rec_top_right_badge(-1rem, 82%, 12rem, 3.6rem, 3.6rem, bold, #fff,
          $primary-color, 0 0 0.5rem 0.5rem, 0 0.3rem 1rem -0.2rem rgba(0, 0, 0, 0.75), -1rem,
          0 0 1rem 1rem, transparent transparent darken($primary-color, 10%) transparent);
}
